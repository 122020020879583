import * as FontAwesome from "react-fontawesome";
import * as MXTS from "@maxxton/cms-mxts-api";
import * as React from "react";
import * as classnames from "classnames";
import * as moment from "moment";

import { AVAILABILITY_CONSTANTS, DATE_FORMAT } from "../../../utils/constants";
import { Availability, SearchFacetState } from "./searchFacet.types";
import { Button, Dropdown, DropdownMenu, DropdownToggle, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip } from "reactstrap";
import { CMSProvidedProperties, CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { LOCAL_STORAGE_KEY, SearchLinkParams, StoredFilter, getAmenityByCode, getAmenityById, getArrivalDateStayPopup, getMxtsEnv, getResortAmenities, resortById } from "../";
import { STAY_PERIOD_DEF_AGGREGATION, UNIT_IDS_AGGREGATION, getAvailability, getBaseFilter } from "../../../utils/availability.util";
import Select, { InputActionMeta, createFilter } from "react-select";
import { getArrivalDates, parseAvailability, updateAmenityCounts } from "./searchFacet.util";
import { getElasticSubjectFilter, getPetCapacity } from "../../../utils/searchFacet.utils";
import { getHideWidgetClass, isClientLoggedIn } from "../../../components/utils";
import { getI18nLocaleString, wrapProps } from "../../../i18n";
import { parse, stringify } from "query-string";

import { DateRangePickerWrapper } from "../../../components/datepicker/DateRangePicker/DateRangePickerWrapper";
import { FocusedInputShape } from "react-dates";
import { Link } from "react-router-dom";
import { SingleDatePickerWrapper } from "../../../components/datepicker/SingleDatePicker/SingleDatePickerWrapper";
import { SmartLink } from "../../../components/SmartLink";
import { Sort } from "./searchFacet.enum";
import { WidgetOptions as TypeWidgetOptions } from "../typesearch/TypeSearch";
import { WidgetOptions as UnitWidgetOptions } from "../unitsearch/UnitSearch";
import { UrlLinkParams } from "../../../utils/urlparam.util";
import { cancelable } from "../../../promise";
import { getStayPeriodDefs } from "../mxts.util";
import loadable from "@loadable/component";
import { loadableRetry } from "../../../utils/loadableComponents.util";
import { memoizedFetchAllBookableSpecials } from "../../../utils/resource.util";
import namespaceList from "../../../i18n/namespaceList";
import { scroller } from "react-scroll";

const AccoKindsFacet = loadable(() => loadableRetry(() => import("./AccoKindsFacet")), {
    resolveComponent: ({ AccoKindsFacet }) => AccoKindsFacet,
});
const ResortsFacet = loadable(() => loadableRetry(() => import("./ResortsFacet")), {
    resolveComponent: ({ ResortsFacet }) => ResortsFacet,
});
const SelectedCriteria = loadable(() => loadableRetry(() => import("./SelectedCriteria")), {
    resolveComponent: ({ SelectedCriteria }) => SelectedCriteria,
});

const SubjectFacet = loadable(() => loadableRetry(() => import("./SubjectFacet")), {
    resolveComponent: ({ SubjectFacet }) => SubjectFacet,
});

type Moment = moment.Moment;

export interface SearchFacetBaseProps {
    className?: string;
    title?: string;
    context: CMSProviderProperties;
    distributionChannelId?: number | null;
    baseUri?: string;
    unitBookUri?: string;
    apiOptions?: MXTS.ApiCallOptions;
    accokinds?: MXTS.AccoKind[];
    resorts?: MXTS.Resort[];
    subjects?: MXTS.Subject[];
    selectedSubjects?: Map<number, number>;
    amenityCategories?: MXTS.AmenityCategory[];
    initialAvailability?: Availability;
    baseFilter: MXTS.AvailabilityRequest;
    numberOfResult?: number;
    stateChanged?: (searchFacetState: SearchFacetState, selected?: boolean) => void;
    dateRangePicker: boolean;
    multiAccoKindSelector: boolean;
    multiResortSelector?: boolean;
    typeSearchWidgetOptions?: TypeWidgetOptions;
    unitSearchWidgetOptions?: UnitWidgetOptions;
    amenityIds?: number[];
    accoKindCriteriaText?: string;
    allAccoKindUrl?: string;
    resortCriteriaText?: string;
    allResortUrl?: string;
    searchButton?: boolean;
    showDateStayPopup: boolean;
    resortSelected?: boolean;
    accoKindSelected?: boolean;
    holiday?: MXTS.StayPeriodDef;
    specials?: MXTS.Resource[];
    hideWidgetOptions?: any;
    hideAccoKinds?: boolean;
    filterDirectSeachByInput?: (input: string) => void;
    filterDirectSearchById?: (input: number | undefined) => void;
    showDirectSearchFilter?: boolean;
    hideUnitDirectSearch?: boolean;
    redirectBookingsEngine: boolean;
    totalTypeSearchResult?: MXTS.Resource[];
    useResortForSubjects?: boolean;
    resortId?: string;
    selectedAmenityCategories?: MXTS.AmenityCategory[] | null;
    defaultSelectedSubjects?: Map<number, number>;
    isFetchingResults?: boolean;
    showStartEndDateLabel: boolean;
    dateFormat: string;
}

export type SearchFacetProps = SearchFacetBaseProps;

// eslint-disable-next-line max-lines-per-function
function getSearchFriendlyURL(defaultURL: string, context: CMSProvidedProperties, resortAmenityCode?: string, accoKindCode?: string): string {
    if (resortAmenityCode) {
        switch (context.currentLocale.code) {
            case "en":
                switch (resortAmenityCode) {
                    case "krim":
                        return "lodging/holiday-park-de-krim";
                    case "californie":
                        return "lodging/residentie-californie";
                    case "hoogelandt":
                        return "lodging/bungalow-park-hoogelandt";
                    case "molenbos":
                        return "lodging/hotel-texel";
                    case "kogerstrand":
                        return "lodging/campsite-kogerstrand";
                    case "loodsmansduin":
                        return "lodging/campsite-loodsmansduin";
                    case "camping":
                        return "lodging/campsite-de-krim";
                    case "shelter":
                        return "lodging/campsite-de-shelter";
                    default:
                        return defaultURL;
                }
            case "nl":
                switch (resortAmenityCode) {
                    case "krim":
                        return "overnachten/vakantiepark-de-krim";
                    case "californie":
                        return "overnachten/residentie-californie";
                    case "hoogelandt":
                        return "overnachten/bungalowpark-hoogelandt";
                    case "molenbos":
                        return "overnachten/hotel-texel";
                    case "kogerstrand":
                        if (context.site.host.indexOf("krim") > -1) {
                            return "overnachten/camping-kogerstrand";
                        }
                        return "/kogerstrand";

                    case "loodsmansduin":
                        if (context.site.host.indexOf("krim") > -1) {
                            return "overnachten/camping-loodsmansduin";
                        }
                        return "/loodsmansduin";

                    case "camping":
                        if (context.site.host.indexOf("krim") > -1) {
                            return "/overnachten/camping-de-krim";
                        }
                        return "/camping-de-krim";

                    case "shelter":
                        if (context.site.host.indexOf("krim") > -1) {
                            return "overnachten/camping-de-shelter";
                        }
                        return "/camping-de-shelter";

                    default:
                        return defaultURL;
                }
            case "de":
                return defaultURL;
            default:
                return defaultURL;
        }
    } else if (accoKindCode) {
        switch (context.currentLocale.code) {
            case "en":
                switch (accoKindCode) {
                    case "bg":
                        return "lodging/bungalow";
                    case "eyt":
                        return "lodging/youth-campsite";
                    case "fv":
                        return "lodging/family-villa";
                    case "ho":
                        return "lodging/hotel-texel";
                    case "sp":
                        return "lodging/seasonal-pitch";
                    case "ap":
                        return "lodging/residentie-californie";
                    case "th":
                        return "lodging/hikers-cabin";
                    case "kp":
                        return "lodging/campsite";
                    case "lv":
                        return "lodging/luxury-villa";
                    case "jp":
                        return "lodging/seasonal-pitch";
                    case "ibgtent":
                        return "lodging/furnished-tent-hire";
                    case "cp":
                        return "lodging/camper-pitch";
                    case "grp":
                        return "lodging/group-camping";
                    case "ch":
                        return "lodging/chalet";
                    case "hoc":
                        return "lodging/hotel-chalet";
                    default:
                        return defaultURL;
                }
            case "nl":
                switch (accoKindCode) {
                    case "bg":
                        return "overnachten/bungalows-texel";
                    case "eyt":
                        return "overnachten/jongerencamping-texel";
                    case "fv":
                        return "overnachten/familievilla-texel";
                    case "ho":
                        return "overnachten/hotel-texel";
                    case "sp":
                        if (context.site.host.indexOf("krim") > -1) {
                            return "overnachten/seizoenplaats-texel";
                        }
                        return "/kamperen/seizoenplaats";

                    case "ap":
                        return "overnachten/residentie-californie";
                    case "th":
                        if (context.site.host.indexOf("krim") > -1) {
                            return "overnachten/trekkershut-texel";
                        }
                        return "/kamperen/trekkershut";

                    case "kp":
                        if (context.site.host.indexOf("krim") > -1) {
                            return "overnachten/kamperen-texel";
                        }
                        return "/kamperen/kampeerplaats";

                    case "lv":
                        return "overnachten/luxe-villa-texel";
                    case "jp":
                        return "overnachten/seizoenplaats-texel";
                    case "ibgtent":
                        if (context.site.host.indexOf("krim") > -1) {
                            return "/campings-texel/ingerichte-tent-huren";
                        }
                        return "/kamperen/tent-huren";
                    case "cp":
                        if (context.site.host.indexOf("krim") > -1) {
                            return "overnachten/camperplaats-texel";
                        }
                        return "/kamperen/camperplaats";

                    case "grp":
                        return "overnachten/groepscamping-texel";
                    case "ch":
                        if (context.site.host.indexOf("krim") > -1) {
                            return "overnachten/chalets-texel";
                        }
                        return "/kamperen/chalet";

                    case "hoc":
                        return "overnachten/hotelchalet-texel";
                    default:
                        return defaultURL;
                }
            case "de":
                return defaultURL;
            default:
                return defaultURL;
        }
    } else {
        return defaultURL;
    }
}

function isSame(x: Moment | null, y: Moment | null) {
    if (x == null || y == null) {
        return x === y;
    }
    return x.isSame(y);
}

function stayPeriodDefById(stayPeriodDefs: MXTS.StayPeriodDef[], id: number): MXTS.StayPeriodDef | null {
    return stayPeriodDefs.find((stayPeriodDef: MXTS.StayPeriodDef) => stayPeriodDef.stayPeriodDefId === id) || null;
}

function accoKindById(accoKinds: MXTS.AccoKind[], id: number): MXTS.AccoKind | null {
    return accoKinds.find((accoKind: MXTS.AccoKind) => accoKind.accommodationkindId === id) || null;
}

function unitById(units: MXTS.Unit[], id: number): MXTS.Unit | null {
    return units.find((unit: MXTS.Unit) => unit.unitId === id) || null;
}

function findAccoKinds(accoKinds: MXTS.AccoKind[], accokindIs: number[]) {
    const availableAccoKinds: MXTS.AccoKind[] = [];
    accoKinds.forEach((accoKind) => {
        const accommodationKind = accokindIs!.indexOf(accoKind.accommodationkindId) > -1 ? accoKind : null;
        if (!accommodationKind) {
            // eslint-disable-next-line  no-console
            console.log(`Unknown accoKind id: ${accommodationKind}`);
        } else {
            availableAccoKinds.push(accommodationKind);
        }
    });
    return availableAccoKinds;
}

const noop = (): void => undefined;

class SearchFacetBase extends React.Component<SearchFacetProps, SearchFacetState> {
    constructor(props: SearchFacetProps) {
        super(props);
        const baseFilter = props.baseFilter;
        const resortAmenityCode = parse(this.props.context.location.search).resort || (this.props.typeSearchWidgetOptions ? this.props.typeSearchWidgetOptions.resortCode : undefined);
        const resortAmenity =
            resortAmenityCode && props.initialAvailability && props.initialAvailability.availableResortAmenities
                ? getAmenityByCode(props.initialAvailability.availableResortAmenities, resortAmenityCode)
                : null;
        const selectedAccoKinds =
            props.accokinds && baseFilter.accommodationkindIds && baseFilter.accommodationkindIds.length > 0
                ? baseFilter.accommodationkindIds.map((accoKind) => accoKindById(props.accokinds!, accoKind)!)
                : undefined;
        const selectedResorts = props.resorts && baseFilter.resortIds && baseFilter.resortIds.length > 0 ? baseFilter.resortIds.map((resort) => resortById(props.resorts!, resort)!) : undefined;
        const startDate = baseFilter.arrivalDate ? moment(baseFilter.arrivalDate, DATE_FORMAT.ELASTIC) : null;
        const minArrivalDate = baseFilter.minArrivalDate ? moment(baseFilter.minArrivalDate, DATE_FORMAT.ELASTIC) : null;
        const maxArrivalDate = baseFilter.maxArrivalDate ? moment(baseFilter.maxArrivalDate, DATE_FORMAT.ELASTIC) : null;
        const endDate = startDate && baseFilter.duration ? moment(baseFilter.arrivalDate, DATE_FORMAT.ELASTIC).add(baseFilter.duration, "day") : null;
        const stayPeriodDefId = baseFilter.stayPeriodDefId ? baseFilter.stayPeriodDefId : null;
        const minCapacity = baseFilter.minCapacity;
        const selectedSubjects: Map<number, number> = new Map();
        if (props.defaultSelectedSubjects) {
            props.defaultSelectedSubjects.forEach((value, key) => {
                selectedSubjects.set(key, +value);
            });
        }
        if (props.selectedSubjects) {
            props.selectedSubjects.forEach((value, key) => {
                selectedSubjects.set(key, +value);
            });
        }
        const state: SearchFacetState = {
            ...props.initialAvailability!,
            isFetching: false,
            startDate,
            modalStartDate: startDate,
            endDate,
            stayPeriodDefId,
            modalStayPeriodDefId: stayPeriodDefId,
            resortAmenity,
            selectedAccoKinds,
            selectedResorts,
            amenityIds: props.amenityIds || props.baseFilter.amenities || [],
            unSavedAmenityIds: [],
            focusedInput: null,
            bookLink: this.renderBookLink(startDate, endDate, stayPeriodDefId, resortAmenity, props.dateRangePicker, props.selectedSubjects, selectedAccoKinds, undefined, undefined, selectedResorts),
            moreFilter: false,
            numberOfResult: props.numberOfResult,
            selectedDirectSearchId: null,
            selectedUnit: null,
            startDatefocus: false,
            moreAmenityCategories: [],
            selectedSubjects: selectedSubjects.size > 0 ? selectedSubjects : undefined,
            sortingOption:
                props.typeSearchWidgetOptions && props.typeSearchWidgetOptions.defaultSortOption !== undefined
                    ? Sort[(props.typeSearchWidgetOptions.defaultSortOption as any) as number]
                    : props.unitSearchWidgetOptions && props.unitSearchWidgetOptions.defaultSortOption !== undefined
                    ? Sort[(props.unitSearchWidgetOptions.defaultSortOption as any) as number]
                    : Sort[Sort.highToLowRating],
            arrivalDateTooltipOpen: true,
            modalStartDatefocus: startDate ? false : true,
            minCapacity,
            holiday: baseFilter.stayHolidayPeriodDefId ? props.holiday : undefined,
            specialCodes: baseFilter.specialCodes,
            currentSubjects: new Map(),
            accokindDropdownOpen: false,
            resortDropdownOpen: false,
            stayDropdownOpen: false,
            disableWidget: true,
            selectedSpecial: "",
            invalidCodeMsg: "",
            orientation: "horizontal",
            minArrivalDate,
            maxArrivalDate,
            directSearchInputValue: "",
        };
        state.newState = state;
        this.state = state;
        if (props.stateChanged) {
            props.stateChanged(this.state);
        }
        this.handleMoreFilter = this.handleMoreFilter.bind(this);
        this.handleArrivalDateModal = this.handleArrivalDateModal.bind(this);
        this.handleSearchBasedOnAmenities = this.handleSearchBasedOnAmenities.bind(this);
        this.handleAmenityChange = this.handleAmenityChange.bind(this);
        this.handleShowMoreAmenities = this.handleShowMoreAmenities.bind(this);
        this.fetchAvailableUnits = this.fetchAvailableUnits.bind(this);
        this.fetchAvailableAccommodationTypes = this.fetchAvailableAccommodationTypes.bind(this);
    }

    public handleMoreFilter() {
        const { amenityIds, availableAmenityCategories } = this.state;
        let ams: number[] = [];
        ams = ams.concat(amenityIds);
        const amenityCategories: MXTS.AmenityCategory[] = availableAmenityCategories ? JSON.parse(JSON.stringify(availableAmenityCategories)) : [];
        this.setState((state) => ({
            ...state,
            invalidCodeMsg: "",
            moreFilter: !state.moreFilter,
            unSavedAmenityIds: ams,
            unSavedAvailableAmenityCategories: amenityCategories,
            unSavedNumberOfResult: state.numberOfResult,
        }));
    }

    public handleArrivalDateModal() {
        this.setState({ arrivalDateModalOpen: this.state.arrivalDateModalOpen ? false : true });
    }

    public componentDidMount() {
        const { unitSearchWidgetOptions, typeSearchWidgetOptions } = this.props;
        const { availableUnits } = this.state;
        this.retrieveFilter();
        if (
            (unitSearchWidgetOptions || typeSearchWidgetOptions) &&
            !availableUnits &&
            typeSearchWidgetOptions &&
            typeSearchWidgetOptions.distributionChannelId !== 700044 &&
            !typeSearchWidgetOptions.multiResortSelector &&
            !typeSearchWidgetOptions.hideUnitDirectSearch
        ) {
            this.fetchAvailableUnits();
        }
        if (window.matchMedia("(max-width: 600px)").matches) {
            this.setState({ orientation: "vertical" });
        } else {
            this.setState({ orientation: "horizontal" });
        }
        window.addEventListener("resize", () => {
            if (window.matchMedia("(max-width: 600px)").matches) {
                this.setState({ orientation: "vertical" });
            } else {
                this.setState({ orientation: "horizontal" });
            }
        });
        this.setState({ disableWidget: !isClientLoggedIn() });

        const startDatePickerElement = document.getElementsByName("searchFacetDateRangePickerStartDate");
        const endDatePickerElement = document.getElementsByName("searchFacetDateRangePickerEndDate");
        if (this.props.showStartEndDateLabel) {
            const startDateLabelElement = document.getElementsByClassName("start-date-label");
            const endDateLabelElement = document.getElementsByClassName("end-date-label");

            if (startDatePickerElement && startDateLabelElement) {
                startDatePickerElement.forEach((item, index) => item.after(startDateLabelElement[index]));
            }
            if (endDatePickerElement && endDateLabelElement) {
                endDatePickerElement.forEach((item, index) => item.after(endDateLabelElement[index]));
            }
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<SearchFacetProps>) {
        const { typeSearchWidgetOptions, totalTypeSearchResult } = nextProps;
        const { availableAccomodationTypes } = this.state;
        if (typeSearchWidgetOptions && totalTypeSearchResult && totalTypeSearchResult.length > 0 && !availableAccomodationTypes && this.props.showDirectSearchFilter) {
            this.fetchAvailableAccommodationTypes(totalTypeSearchResult);
        }
        if (typeSearchWidgetOptions && nextProps.initialAvailability) {
            const { availableDates, availableResorts, availableResortAmenities, availableStayPeriodDefs, availableAccoKinds, availableDurations } = nextProps.initialAvailability;
            this.setState({ availableDates, availableResorts, availableResortAmenities, availableAccoKinds, availableStayPeriodDefs, availableDurations });
        }
    }

    public componentWillUnmount() {
        this.cancelAvailability();
    }

    public componentDidUpdate(prevProps: Readonly<SearchFacetProps>, prevState: Readonly<SearchFacetState>) {
        // if (this.props.unitSearchWidgetOptions || this.props.typeSearchWidgetOptions) {
        //     this.refreshURL();
        // }
        const state = this.state;
        const shouldUpdate = !(
            isSame(prevState.startDate, state.startDate) &&
            isSame(prevState.endDate, state.endDate) &&
            prevState.resortId === state.resortId &&
            prevState.stayPeriodDefId === state.stayPeriodDefId &&
            prevState.holiday === state.holiday &&
            prevState.resortAmenity === state.resortAmenity &&
            prevState.resortAmenities === state.resortAmenities &&
            prevState.amenityIds === state.amenityIds &&
            prevState.selectedAccoKinds === state.selectedAccoKinds &&
            prevState.selectedResorts === state.selectedResorts &&
            prevState.selectedSubjects === state.selectedSubjects &&
            isSame(prevState.modalStartDate, state.modalStartDate) &&
            prevState.modalStayPeriodDefId === state.modalStayPeriodDefId &&
            prevState.minCapacity === state.minCapacity &&
            prevState.specialCodes === state.specialCodes &&
            prevState.subjects === state.subjects
        );

        const isFirstUpdate =
            !prevState.startDate &&
            !prevState.endDate &&
            !prevState.resortId &&
            !prevState.stayPeriodDefId &&
            !prevState.holiday &&
            !prevState.resortAmenity &&
            !prevState.resortAmenities &&
            prevState.amenityIds.length === 0 &&
            !prevState.selectedAccoKinds &&
            !prevState.selectedResorts &&
            !prevState.selectedSubjects &&
            !prevState.modalStartDate &&
            !prevState.stayPeriodDefId &&
            !prevState.minCapacity &&
            !prevState.specialCodes;

        if (shouldUpdate || isFirstUpdate) {
            const { showDateStayPopup, typeSearchWidgetOptions, unitSearchWidgetOptions } = this.props;
            if (
                showDateStayPopup &&
                isSame(prevState.startDate, state.startDate) &&
                isSame(prevState.endDate, state.endDate) &&
                prevState.stayPeriodDefId === state.stayPeriodDefId &&
                (typeSearchWidgetOptions || unitSearchWidgetOptions)
            ) {
                this.setState({ arrivalDateModalOpen: true });
            }

            if ((!state.availableDates || shouldUpdate) && !state.isFetching && !this.props.typeSearchWidgetOptions && !this.props.unitSearchWidgetOptions) {
                this.updateAvailability(
                    isSame(prevState.startDate, state.startDate) &&
                        prevState.stayPeriodDefId === state.stayPeriodDefId &&
                        (!isSame(prevState.modalStartDate, state.modalStartDate) || prevState.modalStayPeriodDefId !== state.modalStayPeriodDefId)
                );
            }
            if (this.props.unitSearchWidgetOptions || this.props.typeSearchWidgetOptions) {
                this.refreshURL();
            }
            this.storeFilter();
        } else {
            this.refreshURL();
            this.storeFilter();
            return;
        }
    }

    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const {
            accoKindSelected,
            baseFilter,
            baseUri,
            dateRangePicker,
            multiAccoKindSelector,
            multiResortSelector,
            subjects,
            className,
            resortSelected,
            typeSearchWidgetOptions,
            searchButton,
            stateChanged,
            title,
            unitSearchWidgetOptions,
            showDateStayPopup,
            specials,
            showDirectSearchFilter,
            hideUnitDirectSearch,
        } = this.props;
        const hideWidget = getHideWidgetClass(this.props.hideWidgetOptions, this.state.disableWidget);
        if (hideWidget === null) {
            return null;
        }
        const {
            arrivalDateModalOpen,
            startDate,
            startDatefocus,
            modalStartDate,
            endDate,
            stayPeriodDefId,
            modalStayPeriodDefId,
            focusedInput,
            availableResortAmenities,
            availableResorts,
            availableAccoKinds,
            availableStayPeriodDefs,
            modalAvailableStayPeriodDefs,
            availableAccomodationTypes,
            availableUnits,
            availableAmenityCategories,
            unSavedAvailableAmenityCategories,
            moreAmenityCategories,
            isFetching,
            bookLink,
            unSavedNumberOfResult,
            selectedSubjects,
            sortingOption,
            modalStartDatefocus,
            specialCodes,
            availableDates,
            availableDurations,
            selectedAccoKinds,
            selectedResorts,
            modalAvailableArrivalDates,
            holiday,
            minCapacity,
            resortAmenity,
            directSearchInputValue,
        } = this.state;
        const { context, selectedAmenityCategories } = this.props;
        const { currentLocale, site } = context;

        const regExp = new RegExp("\\$count");
        const amenitiesRegExp = new RegExp("\\$amenities");
        // eslint-disable-next-line max-len
        const showResult =
            unSavedNumberOfResult !== undefined
                ? getI18nLocaleString(namespaceList.widgetSearchfacet, unSavedNumberOfResult === 1 ? "showResult" : "showResults", currentLocale, site).replace(
                      regExp,
                      unSavedNumberOfResult.toString()
                  )
                : "";
        const opt = !hideUnitDirectSearch && availableUnits ? availableUnits.map((unit: MXTS.Unit) => ({ value: unit.unitId, label: unit.name })) : undefined;
        const searchDirectOpt = showDirectSearchFilter && availableAccomodationTypes ? availableAccomodationTypes.map((accoType: any) => ({ value: accoType.resourceId, label: accoType.name })) : [];
        const resortParam = parse(this.props.context.location.search).resortid;
        const availableResort = availableResorts
            ? availableResorts.find((resort) => (this.state.resortId ? resort.resortId === this.state.resortId : +resort.resortId === +resortParam)) || null
            : null;
        const selectedResort = resortAmenity || (availableResorts ? availableResort : null);
        const resortCriteria = !selectedResorts?.length
            ? [
                  {
                      resort: selectedResort,
                      removable: typeSearchWidgetOptions?.resortCode || (unitSearchWidgetOptions && resortSelected) || !selectedResort ? false : true,
                  },
              ]
            : selectedResorts.map((resort: MXTS.Resort) => ({
                  resort,
                  removable: typeSearchWidgetOptions?.resorts?.some((res) => res.value === resort.resortId) || (unitSearchWidgetOptions && resortSelected) || !resort ? false : true,
              }));
        const accoKindCriterias = selectedAccoKinds?.map((accoKind: MXTS.AccoKind) => ({
            accoKind,
            removable: typeSearchWidgetOptions?.accoKindIds?.some((kind) => kind.value === accoKind.accommodationkindId) || (unitSearchWidgetOptions && accoKindSelected) || !accoKind ? false : true,
        }));
        const specialResource =
            specials && specialCodes?.length
                ? specials.filter((special, indexValue) => (special.searchCode ? specialCodes.indexOf(special.searchCode) === indexValue : specialCodes.indexOf(special.code) === indexValue))
                : undefined;
        const startDateId = "searchFacetDateRangePickerStartDate";
        const endDateId = "searchFacetDateRangePickerEndDate";
        const startDateLabelElement = this.props.showStartEndDateLabel ? (
            <span className="start-date-label"> {getI18nLocaleString(namespaceList.admin, "arrivalLabelAbovePlaceholder", currentLocale, site)} </span>
        ) : (
            ""
        );
        const endDateLabelElement = this.props.showStartEndDateLabel ? (
            <span className="end-date-label"> {getI18nLocaleString(namespaceList.admin, "departureLabelAbovePlaceholder", currentLocale, site)} </span>
        ) : (
            ""
        );
        return (
            <div className={`${hideWidget}`}>
                <div className="sticky-facet">
                    <div className={`search-facet ${className}`}>
                        <div className="background-brand space-pv-l">
                            {title && <h2 className="search-facet-title">{title}</h2>}
                            <Form inline>
                                <div className="plugin-flexbox direction-horizontal search-filter-bar">
                                    <div className="search-inputs plugin">
                                        <div className="filter-wrap">
                                            <div className="overlay" />
                                            {dateRangePicker && (
                                                <React.Fragment>
                                                    <DateRangePickerWrapper
                                                        startDateId="searchFacetDateRangePickerStartDate"
                                                        endDateId="searchFacetDateRangePickerEndDate"
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        onDatesChange={this.handleDatesChange}
                                                        onFocusChange={this.handleFocusChange}
                                                        focusedInput={focusedInput}
                                                        orientation={this.state.orientation}
                                                        availableDates={availableDates}
                                                        availableDurations={availableDurations}
                                                        loading={isFetching}
                                                        isFetchingResults={this.props.isFetchingResults}
                                                        displayFormat={this.props.dateFormat || DATE_FORMAT.DISPLAY}
                                                    />
                                                    {this.props.showStartEndDateLabel && startDateLabelElement}
                                                    {this.props.showStartEndDateLabel && endDateLabelElement}
                                                </React.Fragment>
                                            )}

                                            {!dateRangePicker && (
                                                <div className="datepicker-wrap">
                                                    <SingleDatePickerWrapper
                                                        id="startDate"
                                                        date={startDate}
                                                        onDateChange={this.handleStartDateChange}
                                                        focused={startDatefocus}
                                                        onFocusChange={this.handleStartDateFocusChange}
                                                        placeholder={getI18nLocaleString(namespaceList.widgetSearchfacet, "arrivalDate", currentLocale, site)}
                                                        availableDates={availableDates}
                                                        displayFormat={this.props.dateFormat || DATE_FORMAT.DISPLAY}
                                                    />
                                                    {this.state.startDatefocus && <div className={"datepicker-overlay"} />}
                                                    {(typeSearchWidgetOptions || unitSearchWidgetOptions) && !!document.querySelector("#startDate") && (
                                                        <Tooltip
                                                            isOpen={this.state.startDate ? false : this.state.arrivalDateTooltipOpen && true}
                                                            placement={"bottom"}
                                                            target={"startDate"}
                                                            className={"notification--tooltip"}
                                                        >
                                                            <div>{getI18nLocaleString(namespaceList.widgetSearchfacet, "noArrivalSelected", currentLocale, site)}</div>
                                                            <FontAwesome onClick={this.closeArrivalToolTip} name="times" />
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            )}
                                            {!(typeSearchWidgetOptions && (typeSearchWidgetOptions.resortCode || (typeSearchWidgetOptions.resorts && typeSearchWidgetOptions.resorts.length > 0))) &&
                                                multiResortSelector &&
                                                !(unitSearchWidgetOptions && baseFilter.resortId) && (
                                                    <ResortsFacet
                                                        availableResorts={availableResorts || []}
                                                        resorts={selectedResorts || []}
                                                        disabled={isFetching}
                                                        handleResortsSelection={this.handleResortsSelect}
                                                        configuredResorts={typeSearchWidgetOptions && typeSearchWidgetOptions.resortCode ? [typeSearchWidgetOptions.resortCode] : undefined}
                                                        context={context}
                                                    />
                                                )}
                                            {!(typeSearchWidgetOptions && typeSearchWidgetOptions.resortCode) && !(unitSearchWidgetOptions && resortSelected) && !multiResortSelector && (
                                                <div className="resort-select-wrap resort-facet">
                                                    <Dropdown nav className="resort-inner" isOpen={this.state.resortDropdownOpen} toggle={this.toggleResort}>
                                                        <DropdownToggle nav>
                                                            <span className="filter-data-wrap">
                                                                {this.state.resortAmenity
                                                                    ? this.state.resortAmenity.name
                                                                    : (this.state.resortId || resortParam) && availableResorts && availableResort
                                                                    ? availableResort.name
                                                                    : getI18nLocaleString(namespaceList.widgetSearchfacet, "allResorts", currentLocale, site)}
                                                            </span>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="resort-dropdown">
                                                            <div className="resort-list">
                                                                <div key="allResorts" className="search-window__item" onClick={this.handleResortSelect("", false)}>
                                                                    {getI18nLocaleString(namespaceList.widgetSearchfacet, "allResorts", currentLocale, site)}
                                                                </div>
                                                                {
                                                                    // If only specific amenity categories are selected
                                                                    selectedAmenityCategories && selectedAmenityCategories.length > 0
                                                                        ? getResortAmenities(selectedAmenityCategories, false).map((amenity, index) => this.renderAmenity(amenity, index))
                                                                        : availableResortAmenities && availableResortAmenities.length > 0
                                                                        ? availableResortAmenities.map((amenity, index) => this.renderAmenity(amenity, index))
                                                                        : availableResorts && availableResorts.length > 0
                                                                        ? availableResorts.map((resort, index) => (
                                                                              <div
                                                                                  key={index}
                                                                                  // eslint-disable-next-line max-len
                                                                                  className={`search-window__item ${this.state.resortId === resort.resortId ? "selectedValue" : ""}`}
                                                                                  onClick={this.handleResortSelect("" + resort.resortId, false)}
                                                                              >
                                                                                  {resort.name}
                                                                              </div>
                                                                          ))
                                                                        : null
                                                                }
                                                            </div>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </div>
                                            )}
                                            {!(typeSearchWidgetOptions && typeSearchWidgetOptions.accoKindIds && typeSearchWidgetOptions.accoKindIds.length > 0) &&
                                                multiAccoKindSelector &&
                                                !(unitSearchWidgetOptions && baseFilter.accommodationkindIds) && (
                                                    <AccoKindsFacet
                                                        availableAccoKinds={availableAccoKinds}
                                                        accoKinds={selectedAccoKinds || []}
                                                        disabled={isFetching}
                                                        handleAccoKindsSelection={this.handleAccoKindsSelect}
                                                        configuredAccoKinds={typeSearchWidgetOptions ? typeSearchWidgetOptions.accoKindIds : undefined}
                                                        context={context}
                                                    />
                                                )}
                                            {!(typeSearchWidgetOptions && typeSearchWidgetOptions.accoKindIds && typeSearchWidgetOptions.accoKindIds.length > 0) &&
                                                !multiAccoKindSelector &&
                                                !(unitSearchWidgetOptions && baseFilter.accommodationkindIds) &&
                                                !this.props.hideAccoKinds && (
                                                    <div className="accokind-select-wrap accokinds-facet">
                                                        <Dropdown nav className="accokind-inner" isOpen={this.state.accokindDropdownOpen} toggle={this.toggleAccokind}>
                                                            <DropdownToggle nav>
                                                                <span className="filter-data-wrap">
                                                                    {selectedAccoKinds && selectedAccoKinds.length > 0
                                                                        ? selectedAccoKinds[0].name
                                                                        : getI18nLocaleString(namespaceList.widgetSearchfacet, "allAccoKinds", currentLocale, site)}
                                                                </span>
                                                            </DropdownToggle>
                                                            <DropdownMenu className="accokinds-dropdown">
                                                                <div className="accokinds-list">
                                                                    <div key="allAccokinds" className="search-window__item" onClick={this.handleAccoKindSelect.bind(this, undefined)}>
                                                                        {getI18nLocaleString(namespaceList.widgetSearchfacet, "allAccoKinds", currentLocale, site)}
                                                                    </div>
                                                                    {availableAccoKinds &&
                                                                        availableAccoKinds.map((accoKind, index) => (
                                                                            <div
                                                                                key={index}
                                                                                className={`search-window__item
                                                                        ${
                                                                            selectedAccoKinds && selectedAccoKinds[0] && selectedAccoKinds[0].accommodationkindId === accoKind.accommodationkindId
                                                                                ? "selectedValue"
                                                                                : ""
                                                                        }`}
                                                                                onClick={this.handleAccoKindSelect.bind(this, "" + accoKind.accommodationkindId)}
                                                                            >
                                                                                {accoKind.name}
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </div>
                                                )}
                                            {!dateRangePicker && availableStayPeriodDefs && this.getStayDropDown(availableStayPeriodDefs, stayPeriodDefId, isFetching, this.handleStayChange)}
                                            {showDateStayPopup &&
                                                (startDate === null || stayPeriodDefId === null) &&
                                                getArrivalDateStayPopup(
                                                    null,
                                                    this.handleSetNoSpecial,
                                                    this.handleArrivalDateModal,
                                                    this.handleModalStartDateFocusChange.bind(modalStartDate),
                                                    this.handleModalStartDateChange,
                                                    this.handleModalStayChange,
                                                    modalStartDate,
                                                    modalStayPeriodDefId,
                                                    modalStartDatefocus,
                                                    stateChanged
                                                        ? this.getSearchButton(this.handleModalSearch, modalStartDate === null || modalStayPeriodDefId === null, isFetching)
                                                        : this.getSearchLink(this.getURL(`${bookLink}`), modalStartDate === null || modalStayPeriodDefId === null, isFetching),
                                                    this.props.dateFormat,
                                                    arrivalDateModalOpen,
                                                    isFetching,
                                                    modalAvailableStayPeriodDefs,
                                                    modalAvailableArrivalDates
                                                )}
                                            <SubjectFacet
                                                availableSubjects={subjects || this.state.subjects}
                                                handleSubjectChange={this.handleSubjectChange}
                                                selectedSubjects={selectedSubjects}
                                                disabled={isFetching}
                                                context={context}
                                            />
                                            <div className="facet-btns">
                                                {stateChanged && searchButton && this.getSearchButton(this.handleSearch, isFetching, isFetching)}
                                                {availableAmenityCategories && (
                                                    <div>
                                                        <Button
                                                            disabled={isFetching}
                                                            onClick={this.handleMoreFilter}
                                                            // eslint-disable-next-line max-len
                                                            className="button button--m button--inverse show-all-filters"
                                                        >
                                                            <FontAwesome name="plus" />
                                                            <span className="btn-txt">{getI18nLocaleString(namespaceList.widgetSearchfacet, "moreFilter", currentLocale, site)}</span>
                                                        </Button>
                                                    </div>
                                                )}
                                                {unSavedAvailableAmenityCategories && (
                                                    <Modal
                                                        isOpen={this.state.moreFilter}
                                                        toggle={this.handleMoreFilter}
                                                        size="lg"
                                                        className={`modal-box--full modal-search-facets
                                                        search-filters-expanded ${className}`}
                                                    >
                                                        <ModalHeader tag="h4" toggle={this.handleMoreFilter}>
                                                            {getI18nLocaleString(namespaceList.widgetSearchfacet, "searchFilter", currentLocale, site)}
                                                        </ModalHeader>
                                                        <ModalBody>
                                                            <section className="filter-wrapper typesearch">
                                                                <div className="row">
                                                                    {unSavedAvailableAmenityCategories.map(
                                                                        (amenityCategory: MXTS.AmenityCategory) =>
                                                                            amenityCategory.amenities &&
                                                                            amenityCategory.amenities.length > 0 && (
                                                                                // eslint-disable-next-line max-len
                                                                                <div key={"category-" + amenityCategory.amenityCategoryId} className="col-xl-3 col-lg-4 col-sm-6 filter">
                                                                                    <span key={amenityCategory.amenityCategoryId} className="title">
                                                                                        {amenityCategory.name}
                                                                                    </span>
                                                                                    {amenityCategory.amenities &&
                                                                                        amenityCategory.amenities
                                                                                            .slice(
                                                                                                0,
                                                                                                moreAmenityCategories.indexOf(amenityCategory.amenityCategoryId) < 0
                                                                                                    ? 6
                                                                                                    : amenityCategory.amenities.length
                                                                                            )
                                                                                            .map((am: MXTS.Amenity) => (
                                                                                                <label key={am.amenityId}>
                                                                                                    <Input
                                                                                                        type="checkbox"
                                                                                                        checked={this.isAmenitySelected(am)}
                                                                                                        onChange={this.handleAmenityChange}
                                                                                                        onClick={this.handleAmenityClick}
                                                                                                        id={`${am.amenityId}`}
                                                                                                        disabled={am.isUnavailable}
                                                                                                    />
                                                                                                    <span
                                                                                                        className="amenityName"
                                                                                                        key={`
                                                                                        ${amenityCategory.amenityCategoryId}
                                                                                        ${am.amenityId}
                                                                                    `}
                                                                                                    >
                                                                                                        {am.name}
                                                                                                    </span>
                                                                                                    {typeof am.count !== "undefined" && <span className="amenityCount">{am.count}</span>}
                                                                                                </label>
                                                                                            ))}
                                                                                    {amenityCategory.amenities &&
                                                                                        amenityCategory.amenities.length > 6 &&
                                                                                        moreAmenityCategories.indexOf(amenityCategory.amenityCategoryId) < 0 && (
                                                                                            // eslint-disable-next-line max-len
                                                                                            <a
                                                                                                className="show-more-filters"
                                                                                                onClick={this.handleShowMoreAmenities.bind(this, amenityCategory.amenityCategoryId)}
                                                                                            >
                                                                                                <FontAwesome name="plus" />
                                                                                                {getI18nLocaleString(namespaceList.widgetSearchfacet, "allAmenities", currentLocale, site).replace(
                                                                                                    amenitiesRegExp,
                                                                                                    amenityCategory.name
                                                                                                )}
                                                                                            </a>
                                                                                        )}
                                                                                </div>
                                                                            )
                                                                    )}
                                                                    {!hideUnitDirectSearch && (
                                                                        <div className="col-md-3 filter search-filter">
                                                                            <span className="title">
                                                                                {getI18nLocaleString(namespaceList.widgetSearchfacet, "searchDirectlyAcco", currentLocale, site)}
                                                                            </span>
                                                                            <Select
                                                                                name={""}
                                                                                value={this.getSelectedUnitOption()}
                                                                                onChange={this.handleUnitChange}
                                                                                options={opt}
                                                                                isClearable={false}
                                                                                isDisabled={isFetching}
                                                                                className="react-select-container search-input"
                                                                                classNamePrefix="react-select"
                                                                                filterOption={createFilter({ stringify: (option) => option.label })}
                                                                                placeholder={getI18nLocaleString(namespaceList.widgetSearchfacet, "selectUnit", currentLocale, site)}
                                                                                instanceId="react-select-search-direct-acco"
                                                                            />
                                                                            <SmartLink className="search" href={this.getUnitDetailsLink()} data-role={isFetching ? "disabled" : "enabled"}>
                                                                                <FontAwesome name="search" />
                                                                            </SmartLink>
                                                                        </div>
                                                                    )}
                                                                    {((typeSearchWidgetOptions && typeSearchWidgetOptions.showSpecialCodeOnMoreFilter) ||
                                                                        (unitSearchWidgetOptions && unitSearchWidgetOptions.showSpecialCodeOnMoreFilter)) && (
                                                                        <div className="col-md-3 special-code">
                                                                            <div className="title">
                                                                                {getI18nLocaleString(namespaceList.widgetSearchfacet, "searchDirectlySpecialCode", currentLocale, site)}
                                                                            </div>
                                                                            <div className="special-code__wrapper">
                                                                                <Input
                                                                                    onChange={this.handleSpecialCodeChange}
                                                                                    type="text"
                                                                                    className=""
                                                                                    placeholder={getI18nLocaleString(namespaceList.widgetSearchfacet, "selectSpecial", currentLocale, site)}
                                                                                />
                                                                                {
                                                                                    <Button className="search" onClick={this.getSpecialCodeFiltered}>
                                                                                        <FontAwesome name="search" />
                                                                                    </Button>
                                                                                }
                                                                            </div>
                                                                            {this.state.invalidCodeMsg ? <small className="text-danger">{this.state.invalidCodeMsg}</small> : null}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </section>
                                                        </ModalBody>
                                                        <ModalFooter className="filter-footer">
                                                            <Button className="button button--m button--secondary" onClick={this.handleSearchBasedOnAmenities} disabled={isFetching}>
                                                                {showResult}
                                                            </Button>
                                                        </ModalFooter>
                                                    </Modal>
                                                )}
                                                {baseUri !== undefined && (
                                                    <div>
                                                        {showDateStayPopup &&
                                                            (startDate === null || stayPeriodDefId === null) &&
                                                            this.getSearchButton(this.handleArrivalDateModal, isFetching, isFetching, "searchfacet-btn button button--m button--secondary")}
                                                        {(!showDateStayPopup || (startDate !== null && stayPeriodDefId !== null)) && this.getSearchLink(`${bookLink}`, isFetching, isFetching)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        {showDirectSearchFilter && (
                            <div className="specific-type-search-filter">
                                <input type="checkbox" className="search-type-direct" id="search-type-direct" />
                                <Label className="title" htmlFor="search-type-direct">
                                    <FontAwesome name="plus" />
                                    {getI18nLocaleString(namespaceList.widgetSearchfacet, "searchDirectlyAcco", currentLocale, site)}
                                </Label>
                                <div className="search-direct-container">
                                    <Select
                                        name={""}
                                        value={this.getSelectedDirectSearchOption()}
                                        onInputChange={this.handleInputChange}
                                        onChange={this.handleDirectSearchChange}
                                        inputValue={directSearchInputValue}
                                        options={searchDirectOpt}
                                        isClearable={true}
                                        isDisabled={isFetching}
                                        className="react-select-container type-search-input"
                                        classNamePrefix="react-select"
                                        filterOption={createFilter({ stringify: (option) => option.label })}
                                        placeholder={getI18nLocaleString(namespaceList.widgetSearchfacet, "selectUnit", currentLocale, site)}
                                        instanceId="react-select-search-direct-type"
                                    />
                                    <SmartLink className="search" href={this.getAccoTypeDetailsLink()} data-role={isFetching ? "disabled" : "enabled"}>
                                        {getI18nLocaleString(namespaceList.widgetSearchfacet, "goDirectlyAcco", currentLocale, site)}
                                    </SmartLink>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {this.state.newState && (typeSearchWidgetOptions || unitSearchWidgetOptions) && (
                    <div className="row space-mt-m sort-and-filters">
                        <SelectedCriteria
                            resortCriteria={resortCriteria}
                            accoKindCriterias={accoKindCriterias}
                            accoKindToolTip={this.getAccoKindCriteriaToolTip()}
                            context={context}
                            resortToolTip={this.getResortCriteriaToolTip()}
                            arrivalDate={this.state.startDate}
                            departureDate={this.state.endDate}
                            minArrivalDateCriterta={this.state.minArrivalDate}
                            maxArrivalDateCriterta={this.state.maxArrivalDate}
                            stay={
                                this.state.stayPeriodDefId && this.state.availableStayPeriodDefs
                                    ? stayPeriodDefById(this.state.availableStayPeriodDefs, this.state.stayPeriodDefId)
                                        ? stayPeriodDefById(this.state.availableStayPeriodDefs, this.state.stayPeriodDefId)!.name
                                        : undefined
                                    : undefined
                            }
                            holidayCriteria={{
                                holiday: holiday?.name,
                                removable: typeSearchWidgetOptions?.holidayCode || unitSearchWidgetOptions?.holidayCode || !holiday?.name ? false : true,
                            }}
                            subjectsCriteria={this.getSelectedSubjects(subjects, this.state.selectedSubjects)}
                            amenitiesCriteria={this.getSelectedAmenities(this.state.amenityIds, this.state.availableAmenityCategories)}
                            minCapacityCriteria={{
                                minCapacity,
                                removable: typeSearchWidgetOptions?.minCapacity || unitSearchWidgetOptions?.minCapacity || !minCapacity ? false : true,
                            }}
                            specialCriteria={{
                                specials: specialResource,
                                removable: typeSearchWidgetOptions?.specialCodes || !specialResource ? false : true,
                            }}
                            handleRemoveResort={this.handleRemoveResort}
                            handleRemoveAccoKind={this.handleRemoveAccoKind}
                            handleRemoveArrival={this.handleRemoveArrivalDate}
                            handleRemoveDeparture={this.handleRemoveDepartureDate}
                            handleRemoveStay={this.handleRemoveStay}
                            handleRemoveHolidayStay={this.handleRemoveHolidayStay}
                            handleRemoveAmenity={this.handleRemoveAmenity}
                            handleRemoveSubject={this.handleRemoveSubject}
                            handleRemoveMinCapacity={this.handleRemoveMinCapacity}
                            handleRemoveSpecial={this.handleRemoveSpecial}
                            handleRemoveAll={this.handleRemoveAll}
                        />
                        {typeSearchWidgetOptions && (
                            <div className="col-lg-4 d-flex align-items-center space-mb-s justify-content-end">
                                <span className="space-mr-s">{getI18nLocaleString(namespaceList.widgetSearchfacet, "sorting", currentLocale, site)}</span>
                                <Input type="select" className="sort-select" disabled={isFetching} value={sortingOption} onChange={this.handleSortingSelect}>
                                    {
                                        // Showing Priority option only if its selected by default in the
                                        // Typesearch/unitsearch widget options
                                        ((typeSearchWidgetOptions && typeSearchWidgetOptions.defaultSortOption !== undefined && +typeSearchWidgetOptions.defaultSortOption === Sort.priority) ||
                                            (unitSearchWidgetOptions && unitSearchWidgetOptions.defaultSortOption !== undefined && +unitSearchWidgetOptions.defaultSortOption === Sort.priority)) && (
                                            <option key={Sort.priority} value={Sort[Sort.priority]}>
                                                {getI18nLocaleString(namespaceList.widgetSearchfacet, "defaultSort", currentLocale, site)}
                                            </option>
                                        )
                                    }
                                    <option key={Sort.lowToHighPrice} value={Sort[Sort.lowToHighPrice]}>
                                        {getI18nLocaleString(namespaceList.widgetSearchfacet, "lowToHighPrice", currentLocale, site)}
                                    </option>
                                    <option key={Sort.highToLowPrice} value={Sort[Sort.highToLowPrice]}>
                                        {getI18nLocaleString(namespaceList.widgetSearchfacet, "highToLowPrice", currentLocale, site)}
                                    </option>
                                    <option key={Sort.ascendingName} value={Sort[Sort.ascendingName]}>
                                        {getI18nLocaleString(namespaceList.widgetSearchfacet, "ascendingName", currentLocale, site)}
                                    </option>
                                    <option key={Sort.descendingName} value={Sort[Sort.descendingName]}>
                                        {getI18nLocaleString(namespaceList.widgetSearchfacet, "descendingName", currentLocale, site)}
                                    </option>
                                    <option key={Sort.highToLowRating} value={Sort[Sort.highToLowRating]}>
                                        {getI18nLocaleString(namespaceList.widgetSearchfacet, "highToLowRating", currentLocale, site)}
                                    </option>
                                    <option key={Sort.lowToHighRating} value={Sort[Sort.lowToHighRating]}>
                                        {getI18nLocaleString(namespaceList.widgetSearchfacet, "lowToHighRating", currentLocale, site)}
                                    </option>
                                </Input>
                            </div>
                        )}
                        {unitSearchWidgetOptions && (
                            <div className="col-lg-4 d-flex align-items-center space-mb-s justify-content-end">
                                <span className="space-mr-s">{getI18nLocaleString(namespaceList.widgetSearchfacet, "sorting", currentLocale, site)}</span>
                                <Input type="select" className="sort-select" disabled={isFetching} value={sortingOption} onChange={this.handleSortingSelect}>
                                    <option key={Sort.ascendingName} value={Sort[Sort.ascendingName]}>
                                        {getI18nLocaleString(namespaceList.widgetSearchfacet, "ascendingName", currentLocale, site)}
                                    </option>
                                    <option key={Sort.descendingName} value={Sort[Sort.descendingName]}>
                                        {getI18nLocaleString(namespaceList.widgetSearchfacet, "descendingName", currentLocale, site)}
                                    </option>
                                    <option key={Sort.highToLowRating} value={Sort[Sort.highToLowRating]}>
                                        {getI18nLocaleString(namespaceList.widgetSearchfacet, "highToLowRating", currentLocale, site)}
                                    </option>
                                    <option key={Sort.lowToHighRating} value={Sort[Sort.lowToHighRating]}>
                                        {getI18nLocaleString(namespaceList.widgetSearchfacet, "lowToHighRating", currentLocale, site)}
                                    </option>
                                </Input>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }

    private toggleResort = () => {
        this.setState((state) => ({
            ...state,
            resortDropdownOpen: !state.resortDropdownOpen,
        }));
    };
    private toggleAccokind = () => {
        this.setState((state) => ({
            ...state,
            accokindDropdownOpen: !state.accokindDropdownOpen,
        }));
    };
    private toggleStay = () => {
        this.setState((state) => ({
            ...state,
            stayDropdownOpen: !state.stayDropdownOpen,
        }));
    };

    private closeArrivalToolTip = () => {
        this.setState({ arrivalDateTooltipOpen: false });
    };

    private getURL(bookLink: string) {
        const { modalStartDate, modalStayPeriodDefId, modalAvailableStayPeriodDefs } = this.state;
        const link = bookLink.slice(0, bookLink.indexOf("?") + 1);
        const params = parse(bookLink.slice(bookLink.indexOf("?") + 1));
        if (modalStartDate) {
            params.startdate = modalStartDate.format(DATE_FORMAT.DEFAULT);
        }
        if (modalStayPeriodDefId && modalAvailableStayPeriodDefs) {
            params.stay = stayPeriodDefById(modalAvailableStayPeriodDefs, modalStayPeriodDefId)!.code;
        }
        return `${link}${stringify(params, { encode: false, strict: false })}`;
    }

    private getSearchButton(onClick: (selected?: boolean, e?: React.MouseEvent<HTMLInputElement>) => void, disabled: boolean, isFetching: boolean, className?: string, key?: string): JSX.Element {
        const { currentLocale, site } = this.props.context;
        return (
            <Button className={className ? className : "button button--m button--secondary book-btn"} onClick={onClick as any} disabled={disabled}>
                <FontAwesome name="spinner" className={classnames("searchfacet-progress", isFetching ? "in-progress" : "no-progress")} />
                {" " + getI18nLocaleString(namespaceList.widgetSearchfacet, key ? key : "search", currentLocale, site)}
                <FontAwesome name="spinner" className={"searchfacet-progress no-progress"} />
            </Button>
        );
    }

    private getSearchLink(bookLink: string, disabled: boolean, isFetching: boolean): JSX.Element {
        const { currentLocale, site } = this.props.context;
        return (
            <Link className={disabled ? "disabled" : ""} to={bookLink}>
                <Button className="searchfacet-btn button button--m button--secondary" disabled={disabled} onClick={noop}>
                    <FontAwesome name="spinner" className={classnames("searchfacet-progress", isFetching ? "in-progress" : "no-progress")} />
                    {" " + getI18nLocaleString(namespaceList.widgetSearchfacet, "search", currentLocale, site)}
                    <FontAwesome name="spinner" className={"searchfacet-progress no-progress"} />
                </Button>
            </Link>
        );
    }

    private getStayDropDown(availableStayPeriodDefs: MXTS.StayPeriodDef[], stayPeriodDefId: number | null, isFetching: boolean, handleChange: (id: string) => void) {
        const { currentLocale, site } = this.props.context;
        let selectedStay: MXTS.StayPeriodDef | undefined;
        if (availableStayPeriodDefs && stayPeriodDefId) {
            selectedStay = availableStayPeriodDefs.find((stay) => stay.stayPeriodDefId === stayPeriodDefId);
        }
        return (
            <div className="stay-select-wrap stay-facet">
                <Dropdown nav className="stay-inner" isOpen={this.state.stayDropdownOpen} toggle={this.toggleStay}>
                    <DropdownToggle nav>
                        <span className="filter-data-wrap">{selectedStay ? selectedStay.name : getI18nLocaleString(namespaceList.widgetSearchfacet, "stay", currentLocale, site)}</span>
                    </DropdownToggle>
                    <DropdownMenu className="stay-dropdown">
                        <div className="stay-list">
                            <div key="allStay" className="search-window__item" onClick={handleChange.bind(this, "null")}>
                                {getI18nLocaleString(namespaceList.widgetSearchfacet, "stay", currentLocale, site)}
                            </div>
                            {availableStayPeriodDefs &&
                                availableStayPeriodDefs.map((stay, index) => (
                                    <div
                                        key={index}
                                        className={`search-window__item
                                        ${selectedStay && selectedStay.stayPeriodDefId === stay.stayPeriodDefId ? "selectedValue" : ""}`}
                                        onClick={handleChange.bind(this, "" + stay.stayPeriodDefId)}
                                    >
                                        {stay.name}
                                    </div>
                                ))}
                        </div>
                    </DropdownMenu>
                </Dropdown>
            </div>
        );
    }

    private getSelectedSubjects(subjects?: MXTS.Subject[], selectedSubjects?: Map<number, number>): Map<MXTS.Subject, number> | undefined {
        if (subjects && selectedSubjects) {
            const subs: Map<MXTS.Subject, number> = new Map();
            selectedSubjects.forEach((quantity: number, subjectId: number) => {
                if (quantity > 0) {
                    const currentSubject = subjects.filter((subject) => subject.subjectId === subjectId);
                    if (currentSubject && currentSubject.length > 0) {
                        subs.set(currentSubject[0], quantity);
                    }
                }
            });
            return subs;
        }
    }

    private getSelectedAmenities(selectedAmenities: number[], availableAmenityCategories?: MXTS.AmenityCategory[]): MXTS.Amenity[] | undefined {
        if (availableAmenityCategories && selectedAmenities && selectedAmenities.length > 0) {
            const amenities: MXTS.Amenity[] = [];
            availableAmenityCategories.forEach((cat) => {
                if (cat.amenities) {
                    cat.amenities.forEach((amenity) => {
                        if (selectedAmenities.indexOf(amenity.amenityId) > -1) {
                            amenities.push(amenity);
                        }
                    });
                }
            });
            return amenities;
        }
    }

    private getUnitDetailsLink(): string {
        const { selectedUnit } = this.state;
        const locale = this.props.context.currentLocale.code;
        // eslint-disable-next-line max-len
        return selectedUnit ? `${this.props.unitBookUri}?unitid=${selectedUnit!.unitId}&resourceid=${selectedUnit!.resourceId}&lan=${locale}` : "";
    }

    private getAccoTypeDetailsLink(): string {
        const { selectedDirectSearchId } = this.state;
        if (this.props.unitBookUri && this.props.unitBookUri.indexOf("?") > -1) {
            return selectedDirectSearchId ? `${this.props.unitBookUri}&resourceid=${selectedDirectSearchId!}` : "";
        }
        return selectedDirectSearchId ? `${this.props.unitBookUri}?resourceid=${selectedDirectSearchId!}` : "";
    }

    private getSelectedDirectSearchOption = () => {
        const { showDirectSearchFilter } = this.props;
        const { availableAccomodationTypes, selectedDirectSearchId } = this.state;
        const selectedOption = (showDirectSearchFilter && availableAccomodationTypes?.filter((accoType) => accoType.resourceId === selectedDirectSearchId)) || null;
        if (selectedOption?.length) {
            return { label: selectedOption[0].name, value: selectedOption[0].resourceId };
        }
        return null;
    };

    private handleDirectSearchChange = (event: any) => {
        if (event !== null && this.props.typeSearchWidgetOptions) {
            const { value } = event;
            const selectedDirectSearchId = value !== "null" ? parseInt(value, 10) : null;
            if (selectedDirectSearchId) {
                this.setState({ selectedDirectSearchId }, () => {
                    this.props.filterDirectSearchById!(selectedDirectSearchId);
                });
            }
        } else {
            this.setState({ selectedDirectSearchId: null }, () => {
                this.props.filterDirectSearchById!(undefined);
            });
        }
    };

    private getSpecialCodeFiltered = async () => {
        this.setState({ isFetching: true });
        const { selectedSpecial, specialCodes } = this.state;
        const {
            unitSearchWidgetOptions,
            context: { distributionChannelId, currentLocale, site },
            typeSearchWidgetOptions,
        } = this.props;
        if (selectedSpecial) {
            if (
                window.location.href.indexOf("specialcode") > -1 ||
                (typeSearchWidgetOptions && typeSearchWidgetOptions.specialCodes) ||
                (specialCodes && specialCodes.length > 0 && specialCodes.indexOf(selectedSpecial) > -1)
            ) {
                this.setState({ invalidCodeMsg: getI18nLocaleString(namespaceList.widgetSearchfacet, "codeExitsError", currentLocale, site), isFetching: false });
                return;
            }
            const env = await getMxtsEnv(this.props.context, currentLocale.code);
            // TODO: Instead of getting all the specials get only the selectedSpecial by using appropriate filter for searchCode and code
            // For e.g. fetchSpecialsByCodes
            const allSpecials = distributionChannelId ? await memoizedFetchAllBookableSpecials({ env, distributionChannelId }, this.props.context) : [];
            const validSpecialCode = allSpecials
                ? allSpecials.filter((special) => (special.searchCode ? selectedSpecial.indexOf(special.searchCode) > -1 : selectedSpecial.indexOf(special.code) > -1))
                : undefined;
            if (validSpecialCode && validSpecialCode.length > 0) {
                const apiOptions = this.props.apiOptions || this.state.apiOptions;
                if (!apiOptions) {
                    return;
                }
                const baseFilter = this.getUpdatedBaseFilter();
                baseFilter.specialCodes = specialCodes && specialCodes.length > 0 ? [...specialCodes, selectedSpecial] : [selectedSpecial];
                const availability = await getAvailability(this.props.context, apiOptions, baseFilter);
                const availabileContentsLength = unitSearchWidgetOptions ? availability.response.units?.length : availability.response.resources?.length;
                this.setState({
                    unSavedNumberOfResult: availabileContentsLength,
                    invalidCodeMsg: "",
                    isFetching: false,
                    specialCodes: availabileContentsLength
                        ? // added this because even if length = 0 still in url specials were added
                          specialCodes?.length
                            ? [...specialCodes, selectedSpecial]
                            : [selectedSpecial]
                        : [],
                    selectedSpecial: "",
                });
            } else {
                this.setState({ selectedSpecial: "", invalidCodeMsg: getI18nLocaleString(namespaceList.widgetSearchfacet, "notApplicable", currentLocale, site), isFetching: false });
            }
        }
        this.setState({ isFetching: false });
    };

    private cancelAvailability: () => void = () => undefined;

    private getUpdatedBaseFilter(filterForModal?: boolean): MXTS.AvailabilityRequest {
        const filter: MXTS.AvailabilityRequest = { ...this.props.baseFilter };
        const { endDate, resortAmenity, selectedAccoKinds, selectedResorts, amenityIds, selectedSubjects, specialCodes, resortId } = this.state;
        const { subjects, dateRangePicker } = this.props;
        const startDate = filterForModal ? this.state.modalStartDate : this.state.startDate;
        const stayPeriodDefId = filterForModal ? this.state.modalStayPeriodDefId : this.state.stayPeriodDefId;
        filter.arrivalDate = startDate ? startDate.format(DATE_FORMAT.ELASTIC) : undefined;
        if (this.props.dateRangePicker && startDate && endDate) {
            filter.duration = endDate.diff(startDate, "day");
        }
        filter.stayPeriodDefId = stayPeriodDefId ? stayPeriodDefId : undefined;
        if (!this.state.holiday) {
            filter.stayHolidayPeriodDefId = undefined;
        }
        // TODO active this when elastic-service bug is fixed: filter.resortIds = [resort.resortId].join(",");

        filter.accommodationkindIds = selectedAccoKinds && selectedAccoKinds.length > 0 ? selectedAccoKinds.map((accoKind) => accoKind && accoKind.accommodationkindId!) : undefined;
        filter.resortIds = selectedResorts && selectedResorts.length > 0 ? selectedResorts.map((resort) => resort && resort.resortId) : undefined;
        filter.amenities = amenityIds && amenityIds.length > 0 ? amenityIds : undefined;
        filter.resortId = resortId;
        if (resortAmenity) {
            if (filter.amenities) {
                if (filter.amenities.indexOf(resortAmenity.amenityId) === -1) {
                    filter.amenities.push(resortAmenity.amenityId);
                }
            } else {
                filter.amenities = [resortAmenity.amenityId];
            }
        }
        if (selectedSubjects && selectedSubjects.size > 0) {
            filter.subjects = getElasticSubjectFilter((subjects || this.state.subjects)!, selectedSubjects);
            filter.petCapacity = getPetCapacity((subjects || this.state.subjects)!, selectedSubjects);
        } else {
            filter.subjects = undefined;
            filter.petCapacity = undefined;
        }
        filter.maxDuration = AVAILABILITY_CONSTANTS.MAX_DURATION;
        filter.specialCodes = specialCodes && specialCodes.length > 0 ? specialCodes : undefined;
        if (!dateRangePicker && filter.aggregations.findIndex((aggregation) => aggregation.name === "STAY_PERIOD_DEF_FACET") === -1) {
            filter.aggregations.push(STAY_PERIOD_DEF_AGGREGATION);
        }
        return filter;
    }

    private async fetchAvailableUnits() {
        const apiOptions = this.props.apiOptions || this.state.apiOptions;
        let distributionChannelId: number | null;
        if (this.props.typeSearchWidgetOptions) {
            distributionChannelId = this.props.typeSearchWidgetOptions.distributionChannelId;
        }
        if (this.props.unitSearchWidgetOptions) {
            distributionChannelId = this.props.unitSearchWidgetOptions.distributionChannelId;
        }
        if (apiOptions) {
            const unitElasticFilter = getBaseFilter({
                options: {},
                distributionChannelId: distributionChannelId!,
                customAggregations: [UNIT_IDS_AGGREGATION],
                resortId: distributionChannelId! === 6 || distributionChannelId! === 3133244 ? +this.props.typeSearchWidgetOptions!.resortCode! : undefined,
            });
            const availability = await getAvailability(this.props.context, apiOptions, unitElasticFilter);
            const unitIds: number[] = [...(availability.response.unitFacet || [])];
            let availableUnits: MXTS.Unit[] = [];
            while (unitIds.length) {
                availableUnits = availableUnits.concat((await this.fetchUnits(apiOptions, unitIds.splice(0, 100))).content);
                this.setState({ availableUnits });
            }
        }
    }

    private async fetchAvailableAccommodationTypes(resources: MXTS.Resource[]) {
        const apiOptions = this.props.apiOptions || this.state.apiOptions;
        if (apiOptions && resources && resources.length > 0) {
            const resourceIds: number[] = [];
            resources.forEach((resource) => resourceIds.push(resource.resourceId));
            let availableAccomodationTypes: MXTS.Resource[] = [];
            while (resourceIds.length) {
                availableAccomodationTypes = availableAccomodationTypes.concat((await this.fetchAccomodationTypes(apiOptions, resourceIds.splice(0, 100))).content);
                this.setState({ availableAccomodationTypes });
            }
        }
    }

    private handleInputChange = (inputValue: string, inputActionMeta: InputActionMeta) => {
        if (inputActionMeta.action !== "input-blur" && inputActionMeta.action !== "menu-close") {
            this.setState({ directSearchInputValue: inputValue });
        }
        this.props.filterDirectSeachByInput!(inputValue);
    };

    private async fetchUnits(apiOptions: MXTS.ApiCallOptions, unitIds: number[]): Promise<MXTS.PagedResult<MXTS.Unit>> {
        return this.props.context.mxtsApi.units(apiOptions, { size: 100, unitIds });
    }

    private async fetchAccomodationTypes(apiOptions: MXTS.ApiCallOptions, resourceIds: number[]): Promise<MXTS.PagedResult<MXTS.Resource>> {
        return this.props.context.mxtsApi.resources(apiOptions, { size: 100, resourceIds });
    }

    private renderAmenity = (amenity: MXTS.Amenity, index: number) => (
        <div
            key={index}
            className={`search-window__item
                     ${this.state.resortAmenity && this.state.resortAmenity.amenityId === amenity.amenityId ? "selectedValue" : ""}`}
            onClick={this.handleResortSelect("" + amenity.amenityId, true)}
        >
            {amenity.name}
        </div>
    );

    // eslint-disable-next-line max-lines-per-function
    private async updateAvailability(isModalFiterChanged: boolean) {
        const { alerts, currentLocale, site, mxtsApi, cmsApi } = this.props.context;
        this.setState({ isFetching: true });

        const { accokinds, amenityCategories, unitSearchWidgetOptions, dateRangePicker, searchButton, selectedAmenityCategories } = this.props;
        const apiOptions = this.props.apiOptions || this.state.apiOptions;
        if (apiOptions === undefined) {
            return;
        }
        if (!searchButton && !isModalFiterChanged) {
            if (this.state.isResortSelected) {
                this.handleSearch(true);
            }
        }
        const filter = this.getUpdatedBaseFilter(isModalFiterChanged);

        // Sending all amenities as a standalone request and then resolving them, as a combined request give no result
        const availableSelectedAccokinds: any[] = [];
        let availabilityPerResort: any[] = [];
        if (selectedAmenityCategories && selectedAmenityCategories.length > 0 && !this.state.isResortSelected) {
            let amenities: number[] = [];
            if (!(filter.amenities && filter.amenities.length > 0)) {
                selectedAmenityCategories.forEach((ac) => {
                    amenities.push(...ac.amenities!.map((am) => am.amenityId));
                });
            } else {
                amenities = [...filter.amenities];
            }
            const amenityPromises = amenities.map((amenityID) => {
                filter.amenities = [amenityID];
                return getAvailability(this.props.context, apiOptions, filter);
            });
            availabilityPerResort = await Promise.all(amenityPromises);
            filter.amenities = [];
        } else {
            availabilityPerResort = [await getAvailability(this.props.context, apiOptions, filter)];
        }

        const availabilityFilter = availabilityPerResort[0].filter;
        const response: any =
            selectedAmenityCategories && selectedAmenityCategories.length > 0
                ? {
                      arrivalDates: [],
                      accommodationkinds: [],
                      durations: [],
                      resorts: [],
                      resources: [],
                      stayPeriodDefs: [],
                      amenities: [],
                  }
                : availabilityPerResort[0].response;
        availabilityPerResort.forEach((availability) => {
            response.arrivalDates = Array.from(new Set([...(response.arrivalDates || []), ...(availability.response.arrivalDates || [])]));
            response.accommodationkinds = Array.from(new Set([...(response.accommodationkinds || []), ...(availability.response.accommodationkinds || [])]));
            response.durations = Array.from(new Set([...(response.durations || []), ...(availability.response.durations || [])]));
            response.resorts = Array.from(new Set([...(response.resorts || []), ...(availability.response.resorts || [])]));
            response.resources = Array.from(
                new Set([...(response.resources || []).map((res: any) => JSON.stringify(res)), ...(availability.response.resources || []).map((res: any) => JSON.stringify(res))])
            ).map((res) => JSON.parse(res));
            response.stayPeriodDefs = Array.from(new Set([...(response.stayPeriodDefs || []), ...(availability.response.stayPeriodDefs || [])]));
            response.amenities = Array.from(new Set([...(response.amenities || []), ...(availability.response.amenities || [])]));
        });

        if (!response.arrivalDates || response.arrivalDates.length === 0) {
            this.setState({ isFetching: false });
            alerts.push({
                color: "danger",
                message: getI18nLocaleString(namespaceList.widgetSearchfacet, "noDataFound", currentLocale, site),
            });
            return;
        }
        if (response.units && response.resources!.length > 0) {
            response.resources![0].units = response.units;
        }
        const stayDefIds: number[] = response.stayPeriodDefs!;
        if (isModalFiterChanged) {
            const modalAvailableArrivalDates = getArrivalDates({ filter: availabilityFilter, response });
            this.props.context.mxtsApi.stayPeriodDefs(apiOptions, { size: 40, stayPeriodDefIds: stayDefIds }).then((stays) => {
                const modalAvailableStayPeriodDefs = getStayPeriodDefs(stays.content, { filter: availabilityFilter, response });
                this.setState((state) => ({
                    ...state,
                    isFetching: false,
                    modalAvailableArrivalDates,
                    modalAvailableStayPeriodDefs,
                }));
            });
        } else {
            this.props.context.mxtsApi.stayPeriodDefs(apiOptions, { size: 40, stayPeriodDefIds: stayDefIds }).then((stays) => {
                this.props.context.mxtsApi.resorts(apiOptions, { resortIds: response.resorts }).then((resorts) => {
                    const {
                        availableAccoKinds,
                        availableResorts,
                        availableDates,
                        availableDurations,
                        availableResortAmenities,
                        availableAmenityCategories,
                        availableStayPeriodDefs,
                        modalAvailableArrivalDates,
                        modalAvailableStayPeriodDefs,
                    } = parseAvailability(
                        (accokinds || this.state.accoKinds)!,
                        stays.content,
                        { filter: availabilityFilter, response },
                        amenityCategories,
                        this.state.resortAmenities,
                        resorts.content
                    );

                    this.setState((state) => ({
                        ...state,
                        isFetching: false,
                        availableDates,
                        availableResorts,
                        availableDurations,
                        availableStayPeriodDefs,
                        availableResortAmenities: selectedAmenityCategories && selectedAmenityCategories.length > 0 ? getResortAmenities(selectedAmenityCategories, false) : availableResortAmenities,
                        availableAccoKinds:
                            availableSelectedAccokinds.length > 0 ? findAccoKinds((accokinds || this.state.accoKinds)!, Array.from(new Set(availableSelectedAccokinds))) : availableAccoKinds,
                        availableAmenityCategories,
                        modalAvailableArrivalDates,
                        modalAvailableStayPeriodDefs,
                        bookLink: this.renderBookLink(
                            state.startDate,
                            state.endDate,
                            state.stayPeriodDefId,
                            state.resortAmenity,
                            dateRangePicker,
                            state.selectedSubjects,
                            state.selectedAccoKinds,
                            availableStayPeriodDefs,
                            state.resortId,
                            state.selectedResorts
                        ),
                        numberOfResult: response.resources && response.resources.length > 0 && (unitSearchWidgetOptions ? response.resources[0].units.length : response.resources.length),
                    }));
                });
            });
        }
        this.storeFilter();
    }

    private handleSetNoSpecial = () => false;

    private async retrieveFilter() {
        const { distributionChannelId, amenityCategories, accokinds, subjects, context, useResortForSubjects, resortId, selectedAmenityCategories } = this.props;
        let newResortAmenities: MXTS.Amenity[] | undefined;
        let newAccoKinds: MXTS.AccoKind[] = [];
        let newSubjects: MXTS.Subject[] = [];
        let newApiOptions: MXTS.ApiCallOptions | undefined;
        if (!amenityCategories && !accokinds && !subjects && distributionChannelId) {
            this.setState({ isFetching: true });
            const apiOptions = await getMxtsEnv(context, context.currentLocale.code);
            const [allAccoKinds, allResortAmenities, allSubjects] = await Promise.all([
                this.props.context.mxtsApi.accommodationkinds(apiOptions, { sort: "priority", size: 999 }),
                this.props.context.mxtsApi
                    .amenityCategories(apiOptions, { size: 2000, internetSearchGroup: true, isInternalUse: false, view: "detail", sort: "priority", code: "resort" })
                    .then((cat) => cat.content),
                await this.props.context.mxtsApi
                    .subjects(apiOptions, {
                        types: ["PERSON", "PET"],
                        resortId: useResortForSubjects && resortId ? +resortId : undefined,
                        endDate: moment().format("YYYY-MM-DD"),
                        sort: "subjectOrder",
                    })
                    .then((res) => res.content),
            ]);
            if (allResortAmenities.length > 0) {
                newResortAmenities = allResortAmenities[0].amenities;
            }
            newAccoKinds = allAccoKinds.content;
            newSubjects = allSubjects;
            newApiOptions = apiOptions;
        }
        if (amenityCategories) {
            const resorts =
                selectedAmenityCategories && selectedAmenityCategories.length > 0 ? selectedAmenityCategories : amenityCategories.find((cat: MXTS.AmenityCategory) => cat.code === "resort");
            if (resorts) {
                newResortAmenities = selectedAmenityCategories && selectedAmenityCategories.length > 0 ? getResortAmenities(selectedAmenityCategories, false) : getResortAmenities(amenityCategories);
            }
        }
        const newState = { ...this.state };
        newState.resortAmenities = newResortAmenities;
        if (!accokinds && !subjects && distributionChannelId) {
            newState.accoKinds = newAccoKinds;
            newState.subjects = newSubjects;
            newState.isFetching = false;
            newState.apiOptions = newApiOptions;
        }
        const storedFilterStr = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (storedFilterStr) {
            const storedFilter: StoredFilter = JSON.parse(storedFilterStr);
            if (storedFilter) {
                if (storedFilter.startDate) {
                    const startDate = moment(storedFilter.startDate, DATE_FORMAT.DEFAULT);
                    if (!startDate.isBefore(moment())) {
                        newState.startDate = newState.startDate || startDate;
                        newState.modalStartDate = newState.startDate;
                    }
                }
                if (storedFilter.endDate) {
                    const endDate = moment(storedFilter.endDate, DATE_FORMAT.DEFAULT);
                    if (!endDate.isBefore(moment())) {
                        newState.endDate = newState.endDate || endDate;
                    }
                }
                if (storedFilter.stayPeriodDefId) {
                    newState.stayPeriodDefId = newState.stayPeriodDefId || storedFilter.stayPeriodDefId;
                    newState.modalStayPeriodDefId = newState.stayPeriodDefId;
                }
                if (storedFilter.subjects && storedFilter.subjects.length > 0) {
                    const subjectsMap: Map<number, number> = new Map();
                    storedFilter.subjects.map((subject: MXTS.SubjectQuantity) => {
                        subjectsMap.set(subject.subjectId, subject.quantity);
                    });
                    newState.selectedSubjects = newState.selectedSubjects && newState.selectedSubjects.size > 0 ? newState.selectedSubjects : subjectsMap;
                }
            }
        }
        newState.newState = newState;
        this.setState(newState);
    }

    private storeFilter() {
        const { startDate, endDate, stayPeriodDefId, selectedSubjects } = this.state;
        const { dateRangePicker } = this.props;

        const subjects: MXTS.SubjectQuantity[] = [];
        if (selectedSubjects) {
            selectedSubjects.forEach((quantity: number, subjectId: number) => {
                if (quantity > 0) {
                    subjects.push({ subjectId, quantity });
                }
            });
        }
        const storedFilter: StoredFilter = {
            startDate: startDate != null ? startDate.format(DATE_FORMAT.DEFAULT) : null,
            endDate: endDate != null && dateRangePicker ? endDate.format(DATE_FORMAT.DEFAULT) : null,
            stayPeriodDefId: dateRangePicker ? undefined : stayPeriodDefId,
            subjects,
        };
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(storedFilter));
    }

    // eslint-disable-next-line max-lines-per-function
    private refreshURL(remove?: boolean) {
        const {
            availableAmenityCategories,
            availableStayPeriodDefs,
            amenityIds,
            startDate,
            endDate,
            stayPeriodDefId,
            minCapacity,
            selectedSubjects,
            selectedAccoKinds,
            selectedResorts,
            resortAmenity,
            holiday,
            specialCodes,
        } = this.state;
        const { typeSearchWidgetOptions } = this.props;
        const { href, search } = this.props.context.location;
        const params: UrlLinkParams = parse(search);
        params.startdate = startDate ? startDate.format(DATE_FORMAT.DEFAULT) : undefined;
        params.enddate = endDate ? endDate.format(DATE_FORMAT.DEFAULT) : undefined;
        if (!params.stay) {
            if (stayPeriodDefId && availableStayPeriodDefs) {
                availableStayPeriodDefs.forEach((stayDef: MXTS.StayPeriodDef) => {
                    if (stayDef && stayDef.stayPeriodDefId === +stayPeriodDefId) {
                        params.stay = stayDef.code;
                    }
                });
                params.stayperioddefid = undefined;
            }
        }
        if (typeSearchWidgetOptions && specialCodes && specialCodes.join(",") && specialCodes.join(",") !== typeSearchWidgetOptions.specialCodes) {
            params.specialcode = specialCodes;
        } else if (specialCodes && specialCodes.length === 0) {
            params.specialcode = specialCodes;
        }
        params.minCapacity = minCapacity;
        params.holiday = holiday ? holiday.code : undefined;
        const filteredAmenities = amenityIds?.length ? [...amenityIds] : [];
        if (resortAmenity && amenityIds.length > 0 && amenityIds.indexOf(resortAmenity.amenityId) > -1) {
            filteredAmenities.splice(amenityIds.indexOf(resortAmenity.amenityId), 1);
        }
        if (filteredAmenities && availableAmenityCategories) {
            let amenities: string[] = [];
            availableAmenityCategories.forEach((cat: MXTS.AmenityCategory) => {
                if (cat.amenities) {
                    cat.amenities.forEach((amenity: MXTS.Amenity) => {
                        if (
                            filteredAmenities.indexOf(amenity.amenityId) !== -1 &&
                            typeSearchWidgetOptions &&
                            typeSearchWidgetOptions.amenityCodes &&
                            typeSearchWidgetOptions.amenityCodes.split(",").indexOf(amenity.identifier) === 0
                        ) {
                            amenities.push(amenity.identifier);
                        } else if (filteredAmenities.indexOf(amenity.amenityId) !== -1) {
                            amenities.push(amenity.identifier);
                        }
                    });
                }
            });
            amenities = Array.from(new Set(amenities));
            params.amenity = amenities.length > 0 ? amenities.join() : undefined;
            params.amenities = undefined;
        }
        if (selectedSubjects) {
            const subjects: string[] = [];
            selectedSubjects.forEach((value: number, key: number) => {
                if (value > 0) {
                    subjects.push(key.toString() + "," + value.toString());
                }
            });
            params.subject = subjects;
        } else {
            params.subject = undefined;
        }
        params.accokindids =
            (!typeSearchWidgetOptions || !(typeSearchWidgetOptions.accoKindIds && typeSearchWidgetOptions.accoKindIds.length !== 0)) && selectedAccoKinds
                ? selectedAccoKinds.map((accoKind) => accoKind.accommodationkindId)
                : undefined;
        params.resortids =
            (!typeSearchWidgetOptions || !typeSearchWidgetOptions.resortCode) && selectedResorts ? selectedResorts.map((resort) => resort?.resortId).filter((resortId) => resortId) : undefined;
        params.resort = (!typeSearchWidgetOptions || !typeSearchWidgetOptions.resortCode) && resortAmenity ? resortAmenity.identifier : undefined;
        params.resortid = remove
            ? undefined
            : typeSearchWidgetOptions && typeSearchWidgetOptions.resortCode && !isNaN(+typeSearchWidgetOptions.resortCode)
            ? typeSearchWidgetOptions.resortCode
            : this.state.resortId || params.resortid;
        if (params) {
            const origin = href.indexOf("?") !== -1 ? href.slice(0, href.indexOf("?")) : href;
            let newUrl = "";
            // Filtering out undefined and empty array items
            const validUrlParams: UrlLinkParams = {};
            for (const key in params) {
                if (Object.prototype.hasOwnProperty.call(params, key)) {
                    const queryParam = params[key as keyof UrlLinkParams];
                    if ((queryParam && !Array.isArray(queryParam)) || (Array.isArray(queryParam) && queryParam.length > 0)) {
                        (validUrlParams[key as keyof UrlLinkParams] as typeof queryParam) = queryParam;
                    }
                }
            }
            // Only if we are getting a valid/ not empty object we are changing the url
            if (validUrlParams && Object.keys(validUrlParams).length > 0) {
                newUrl = origin + "?" + stringify(validUrlParams, { encode: false, strict: false });
            } else if (Object.keys(validUrlParams).length === 0) {
                newUrl = origin + stringify(validUrlParams, { encode: false, strict: false });
            }
            window.history.replaceState("Search criteria", "Search accommodations", newUrl);
        }
    }

    private renderBookLink(
        startDate: Moment | null,
        endDate: Moment | null,
        stayPeriodDefId: number | null,
        resortAmenity: MXTS.Amenity | null,
        dateRangePicker: boolean,
        selectedSubjects?: Map<number, number>,
        accoKinds?: MXTS.AccoKind[],
        availableStayPeriodDefs?: MXTS.StayPeriodDef[],
        resortId?: number,
        selectedResorts?: MXTS.Resort[]
    ): string {
        const { baseUri, context, redirectBookingsEngine } = this.props;
        if (!baseUri) {
            return "";
        }
        const params: SearchLinkParams = {};
        if (startDate != null) {
            params.startdate = startDate.format(DATE_FORMAT.DEFAULT);
        }
        if (dateRangePicker && endDate != null) {
            params.enddate = endDate.format(DATE_FORMAT.DEFAULT);
        }
        if (!dateRangePicker && availableStayPeriodDefs && stayPeriodDefId) {
            const stay = stayPeriodDefById(availableStayPeriodDefs, stayPeriodDefId);
            if (stay) {
                params.stay = stay.code;
            }
        }
        if (resortAmenity != null) {
            params.resort = resortAmenity.identifier;
        } else if (!resortAmenity) {
            params.resortid = resortId;
        }
        if (accoKinds != null) {
            params.accokindids = accoKinds.map((accoKind) => accoKind.accommodationkindId);
        }
        if (selectedResorts) {
            params.resortids = selectedResorts.map((resort) => resort.resortId);
        }
        if (selectedSubjects) {
            const subjects: string[] = [];
            selectedSubjects.forEach((value: number, key: number) => {
                if (value > 0) {
                    subjects.push(key.toString() + "," + value.toString());
                }
            });
            params.subject = subjects;
        }
        // Do not add lan param because this component is KRIM specific and KRIM do not use multilanguage
        // params.lan = context.currentLocale.code;
        // If redirectBookingsEngine enabled, then it will redirect to object page, otherwise default hard coded page.
        const searchFriendlyURL = redirectBookingsEngine
            ? ""
            : getSearchFriendlyURL(baseUri, context, resortAmenity ? resortAmenity.identifier : undefined, accoKinds && accoKinds.length > 0 ? accoKinds[0].code : undefined);
        const bookLink = `${searchFriendlyURL || baseUri}?${stringify(params, { encode: false, strict: false })}`;
        return bookLink;
    }

    private handleDatesChange = ({ startDate, endDate }: { startDate: Moment; endDate: Moment }): void => {
        scrollToTop();
        this.setState({ startDate, endDate, filterChanged: true }, () => {
            if (this.props.stateChanged) {
                this.props.stateChanged(this.state);
            }
        });
    };

    private handleStartDateChange = (startDate: Moment): void => {
        scrollToTop();
        this.setState({ startDate, modalStartDate: startDate, filterChanged: true }, () => {
            if (this.props.stateChanged) {
                this.props.stateChanged(this.state);
            }
        });
    };

    private handleModalStartDateChange = (modalStartDate: Moment): void => {
        this.setState({ modalStartDate });
    };

    private handleStartDateFocusChange = (arg: { focused: boolean | null }): void => {
        if (arg.focused) {
            this.closeArrivalToolTip();
        }
        this.setState((state) => ({
            ...state,
            startDatefocus: !!arg.focused,
            accokindDropdownOpen: false,
            resortDropdownOpen: false,
            stayDropdownOpen: false,
        }));
    };

    private handleModalStartDateFocusChange = () => {
        this.setState((state) => ({ modalStartDatefocus: !state.modalStartDatefocus }));
    };

    private handleStayChange = (id: string) => {
        scrollToTop();
        const stayPeriodDefId = id !== "null" ? +id : null;
        this.setState({ stayPeriodDefId, modalStayPeriodDefId: stayPeriodDefId, filterChanged: true }, () => {
            if (this.props.stateChanged) {
                this.props.stateChanged(this.state);
            }
        });
        this.toggleStay();
    };

    private handleModalStayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const modalStayPeriodDefId = value !== "null" ? +value : null;
        this.setState({ modalStayPeriodDefId });
    };

    private handleFocusChange = (focusedInput: FocusedInputShape): void => {
        const { startDate } = this.state;
        let focus = focusedInput;
        if (!startDate && focusedInput === "endDate" && !this.state.focusedInput) {
            focus = "startDate";
        }
        this.setState({ focusedInput: focus });
    };

    private handleResortSelect = (id: string, isAmenity?: boolean) => () => {
        scrollToTop();
        const { amenityCategories, selectedAmenityCategories } = this.props;
        const { amenityIds, availableResortAmenities, resortAmenity, isResortSelected } = this.state;

        const resortAmenities =
            selectedAmenityCategories && selectedAmenityCategories.length > 0
                ? getResortAmenities(selectedAmenityCategories, false)
                : availableResortAmenities || getResortAmenities(amenityCategories!);
        const resortAmenityId = id ? parseInt(id, 10) : null;
        const selectedResortAmenity = resortAmenityId != null ? getAmenityById(resortAmenities, resortAmenityId) : null;
        const state = { ...this.state, resortAmenity: selectedResortAmenity, filterChanged: true };
        if (amenityIds?.length && resortAmenity && amenityIds.indexOf(resortAmenity.amenityId) > -1) {
            const amenities = [...amenityIds];
            amenities.splice(amenityIds.indexOf(resortAmenity.amenityId), 1);
            state.amenityIds = amenities;
        }
        if (!id && isResortSelected) {
            state.isResortSelected = false;
        }
        state.resortId = (!id && !isAmenity && undefined) || (!isAmenity && parseInt(id, 10)) || undefined;
        this.setState(state, () => {
            if (this.props.stateChanged) {
                this.props.stateChanged(this.state);
            }
        });
        this.toggleResort();
    };

    private handleAccoKindSelect = (id?: string) => {
        scrollToTop();
        const { accokinds } = this.props;
        const accoKindId = id ? parseInt(id, 10) : null;
        const accoKind = accoKindId != null ? accoKindById(accokinds! || this.state.accoKinds, accoKindId) : null;
        const { resortAmenities, amenityIds, resortAmenity } = this.state;
        const selected = resortAmenities && resortAmenities.some((am) => (resortAmenity && resortAmenity.amenityId === am.amenityId) || amenityIds.some((amen) => amen === am.amenityId));
        this.setState({ selectedAccoKinds: accoKind && ([accoKind] as any), filterChanged: true, isResortSelected: selected }, () => {
            if (this.props.stateChanged) {
                this.props.stateChanged(this.state);
            }
        });
        this.toggleAccokind();
    };

    private handleResortsSelect = (selectedResorts: MXTS.Resort[]) => {
        scrollToTop();
        this.setState({ selectedResorts, filterChanged: true }, () => {
            if (this.props.stateChanged) {
                this.props.stateChanged(this.state);
            }
        });
    };

    private handleAccoKindsSelect = (selectedAccoKinds: MXTS.AccoKind[]) => {
        scrollToTop();
        this.setState({ selectedAccoKinds, filterChanged: true }, () => {
            if (this.props.stateChanged) {
                this.props.stateChanged(this.state);
            }
        });
    };

    private handleSubjectChange = (selectedSubjects: Map<number, number>) => {
        scrollToTop();
        this.setState({ selectedSubjects, filterChanged: true }, () => {
            if (this.props.stateChanged) {
                this.props.stateChanged(this.state);
            }
        });
    };

    private handleSortingSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        for (const sort in Sort) {
            if (sort === value) {
                await this.setState({ sortingOption: value });
                break;
            }
        }
        this.props.stateChanged!(this.state);
    };

    private isAmenitySelected(amenity: MXTS.Amenity): boolean {
        const { unSavedAmenityIds } = this.state;
        return unSavedAmenityIds.indexOf(amenity.amenityId) > -1;
    }

    private handleAmenityClick = (e: React.MouseEvent<HTMLInputElement>) => {
        e.stopPropagation();
    };

    private async handleSearchBasedOnAmenities() {
        scrollToTop();
        const { unSavedAmenityIds, unSavedAvailableAmenityCategories } = this.state;
        let ams: number[] = [];
        ams = ams.concat(unSavedAmenityIds);
        const amenityCategories: MXTS.AmenityCategory[] = unSavedAvailableAmenityCategories ? JSON.parse(JSON.stringify(unSavedAvailableAmenityCategories)) : [];
        await this.setState((state) => ({
            ...state,
            moreFilter: !state.moreFilter,
            amenityIds: ams,
            filterChanged: true,
            availableAmenityCategories: amenityCategories,
            numberOfResult: state.unSavedNumberOfResult,
        }));
        this.props.stateChanged!(this.state);
    }

    private handleShowMoreAmenities(amenityCategoryId: number) {
        const { moreAmenityCategories } = this.state;
        moreAmenityCategories.push(amenityCategoryId);
        this.setState({ moreAmenityCategories });
    }

    private handleSearch = (selected?: boolean, e?: React.MouseEvent<HTMLInputElement>) => {
        if (!this.props.showDateStayPopup || this.state.startDate || this.state.stayPeriodDefId) {
            this.updateNewState(this.state);
            this.props.stateChanged!(this.state, selected);
        } else if (e) {
            this.handleArrivalDateModal();
        }
    };

    private handleModalSearch = () => {
        scrollToTop();
        const { modalStartDate, modalStayPeriodDefId } = this.state;
        this.setState({ startDate: modalStartDate, stayPeriodDefId: modalStayPeriodDefId });
        this.handleArrivalDateModal();
        this.updateNewState({ ...this.state, startDate: modalStartDate, stayPeriodDefId: modalStayPeriodDefId });
        this.props.stateChanged!({ ...this.state, startDate: modalStartDate, stayPeriodDefId: modalStayPeriodDefId, filterChanged: true });
    };

    private handleAmenityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ isFetching: true });
        const { unSavedAmenityIds } = this.state;
        const target = event.target;
        const value = target.checked;
        const amenityId = parseInt(target.id, 10);
        if (value) {
            unSavedAmenityIds.push(amenityId);
        } else {
            const index = unSavedAmenityIds.indexOf(amenityId, 0);
            if (index > -1) {
                unSavedAmenityIds.splice(index, 1);
            }
        }
        const { unitSearchWidgetOptions } = this.props;
        const apiOptions = this.props.apiOptions || this.state.apiOptions;
        if (apiOptions === undefined) {
            return;
        }
        const baseFilter = this.getUpdatedBaseFilter();
        baseFilter.amenities = unSavedAmenityIds;
        const [availabilityProm, cancelAvailability] = cancelable(getAvailability(this.props.context, apiOptions, baseFilter));
        this.cancelAvailability = cancelAvailability;
        availabilityProm.then((availability) => {
            const { unSavedAvailableAmenityCategories } = this.state;
            if (unSavedAvailableAmenityCategories) {
                unSavedAvailableAmenityCategories.forEach((category: MXTS.AmenityCategory) => {
                    if (category.amenities) {
                        category.amenities.forEach((ame) => {
                            const isAvailable = availability.response.amenities.some((amenity) => amenity.key === ame.amenityId);
                            ame.isUnavailable = !isAvailable;
                        });
                    }
                    updateAmenityCounts(availability, category);
                });
            }
            const unSavedNumberOfResult = unitSearchWidgetOptions ? availability.response.units?.length : availability.response.resources?.length;
            this.setState({
                unSavedNumberOfResult,
                unSavedAmenityIds,
                isFetching: false,
            });
        });
    };

    private getSelectedUnitOption = () => {
        const { hideUnitDirectSearch } = this.props;
        const { availableUnits, selectedUnit } = this.state;
        const selectedUnitOptions = (!hideUnitDirectSearch && selectedUnit && availableUnits?.filter((unit: MXTS.Unit) => unit.unitId === selectedUnit.unitId)) || null;
        if (selectedUnitOptions?.length) {
            return { label: selectedUnitOptions[0].name, value: selectedUnitOptions[0].unitId };
        }
        return null;
    };

    private handleUnitChange = (event: any) => {
        const { availableUnits } = this.state;
        if (availableUnits) {
            const value = event?.value;
            const selectedUnitId = parseInt(value, 10) || null;
            const selectedUnit = selectedUnitId != null ? unitById(availableUnits, selectedUnitId) : null;
            this.setState({ selectedUnit });
        }
    };

    private handleSpecialCodeChange = (event: any) => {
        if (event !== null) {
            const { value } = event.target;
            this.setState({ selectedSpecial: value, invalidCodeMsg: "" });
        }
    };

    private getAccoKindCriteriaToolTip(): JSX.Element | undefined {
        const {
            accoKindCriteriaText,
            allAccoKindUrl,
            context: { currentLocale, site },
        } = this.props;
        return accoKindCriteriaText && allAccoKindUrl ? (
            <div>
                {`${accoKindCriteriaText} `}
                <SmartLink href={allAccoKindUrl}>{getI18nLocaleString(namespaceList.widgetSearchfacet, "viewAllAccoKinds", currentLocale, site)}</SmartLink>
            </div>
        ) : undefined;
    }

    private getResortCriteriaToolTip(): JSX.Element | undefined {
        const {
            resortCriteriaText,
            allResortUrl,
            context: { currentLocale, site },
        } = this.props;
        return resortCriteriaText && allResortUrl ? (
            <div>
                {`${resortCriteriaText} `}
                <SmartLink href={allResortUrl}>{getI18nLocaleString(namespaceList.widgetSearchfacet, "viewAllResorts", currentLocale, site)}</SmartLink>
            </div>
        ) : undefined;
    }

    private handleRemoveResort = (resort: MXTS.Resort) => {
        const { typeSearchWidgetOptions } = this.props;
        const { amenityIds, resortAmenity, selectedResorts } = this.state;
        const filteredResorts = selectedResorts?.filter((res) => res.resortId !== resort.resortId);
        if (typeSearchWidgetOptions && typeSearchWidgetOptions.resortCode ? false : true) {
            const state = { ...this.state, resortAmenity: null, resortId: undefined, isResortSelected: false, selectedResorts: filteredResorts };
            if (resortAmenity && amenityIds.length > 0 && amenityIds.indexOf(resortAmenity.amenityId) > -1) {
                const amenities = [...amenityIds];
                amenities.splice(amenityIds.indexOf(resortAmenity.amenityId), 1);
                state.amenityIds = amenities;
            }
            this.setState(state, () => {
                this.refreshURL(true);
                this.updateNewState(state);
                if (this.props.stateChanged) {
                    this.props.stateChanged(state);
                }
            });
        }
    };

    private handleRemoveAccoKind = (accoKind: MXTS.AccoKind) => {
        const { resortAmenities, amenityIds, resortAmenity } = this.state;
        const selectedAccoKinds = this.state.selectedAccoKinds!.filter((kind) => kind.accommodationkindId !== accoKind.accommodationkindId);
        const selected = resortAmenities && resortAmenities.some((am) => (resortAmenity && resortAmenity.amenityId === am.amenityId) || amenityIds.some((amen) => amen === am.amenityId));
        const state = { ...this.state, selectedAccoKinds };
        this.setState(state);
        this.updateNewState(state);
        if (this.props.stateChanged) {
            this.props.stateChanged(state, selected);
        }
    };

    private handleRemoveSubject = (subject: MXTS.Subject) => {
        const { resortAmenities, amenityIds, resortAmenity } = this.state;
        const selectedSubjects: Map<number, number> = new Map();
        this.state.selectedSubjects!.forEach((count: number, subjectId: number) => {
            if (subjectId !== subject.subjectId) {
                selectedSubjects.set(subjectId, count);
            }
        });
        const selected = resortAmenities && resortAmenities.some((am) => (resortAmenity && resortAmenity.amenityId === am.amenityId) || amenityIds.some((amen) => amen === am.amenityId));
        const state = { ...this.state, selectedSubjects };
        this.setState(state);
        this.updateNewState(state);
        if (this.props.stateChanged) {
            this.props.stateChanged(state, selected);
        }
    };

    private handleRemoveMinCapacity = () => {
        const { resortAmenities, amenityIds, resortAmenity } = this.state;
        const state = { ...this.state, minCapacity: undefined };
        const selected = resortAmenities && resortAmenities.some((am) => (resortAmenity && resortAmenity.amenityId === am.amenityId) || amenityIds.some((amen) => amen === am.amenityId));
        this.setState(state);
        this.updateNewState(state);
        if (this.props.stateChanged) {
            this.props.stateChanged(state, selected);
        }
    };

    private handleRemoveSpecial = (special: string) => {
        const { resortAmenities, amenityIds, resortAmenity } = this.state;
        const state = {
            ...this.state,
            specialCodes: this.state.specialCodes!.filter((specialCode) => specialCode !== special),
        };
        const selected = resortAmenities && resortAmenities.some((am) => (resortAmenity && resortAmenity.amenityId === am.amenityId) || amenityIds.some((amen) => amen === am.amenityId));
        this.setState(state);
        this.updateNewState(state);
        if (this.props.stateChanged) {
            this.props.stateChanged(state, selected);
        }
    };

    private handleRemoveAmenity = (amenity: MXTS.Amenity) => {
        const { resortAmenities, amenityIds, resortAmenity } = this.state;
        const index = this.state.amenityIds.indexOf(amenity.amenityId);
        const amenities = [...this.state.amenityIds];
        amenities.splice(index, 1);
        const state = { ...this.state, amenityIds: amenities, amenities };
        const selected = resortAmenities && resortAmenities.some((am) => (resortAmenity && resortAmenity.amenityId === am.amenityId) || amenityIds.some((amen) => amen === am.amenityId));
        this.setState(state, () => {
            if (this.props.stateChanged) {
                this.props.stateChanged(state, selected);
            }
        });
        this.updateNewState(state);
    };

    private handleRemoveArrivalDate = () => {
        const { resortAmenities, amenityIds, resortAmenity } = this.state;
        const state = { ...this.state, startDate: null };
        const selected = resortAmenities && resortAmenities.some((am) => (resortAmenity && resortAmenity.amenityId === am.amenityId) || amenityIds.some((amen) => amen === am.amenityId));
        this.setState(state);
        this.updateNewState(state);
        if (this.props.stateChanged) {
            this.props.stateChanged(state, selected);
        }
    };

    private handleRemoveDepartureDate = () => {
        const { resortAmenities, amenityIds, resortAmenity } = this.state;
        const state = { ...this.state, endDate: null };
        const selected = resortAmenities && resortAmenities.some((am) => (resortAmenity && resortAmenity.amenityId === am.amenityId) || amenityIds.some((amen) => amen === am.amenityId));
        this.setState(state);
        this.updateNewState(state);
        if (this.props.stateChanged) {
            this.props.stateChanged(state, selected);
        }
    };

    private handleRemoveStay = () => {
        const { resortAmenities, amenityIds, resortAmenity } = this.state;
        const state = { ...this.state, stayPeriodDefId: null };
        const selected = resortAmenities && resortAmenities.some((am) => (resortAmenity && resortAmenity.amenityId === am.amenityId) || amenityIds.some((amen) => amen === am.amenityId));
        this.setState(state);
        this.updateNewState(state);
        if (this.props.stateChanged) {
            this.props.stateChanged(state, selected);
        }
    };

    private handleRemoveHolidayStay = () => {
        const { resortAmenities, amenityIds, resortAmenity } = this.state;
        const state = { ...this.state, holiday: undefined };
        const selected = resortAmenities && resortAmenities.some((am) => (resortAmenity && resortAmenity.amenityId === am.amenityId) || amenityIds.some((amen) => amen === am.amenityId));
        this.setState(state);
        this.updateNewState(state);
        if (this.props.stateChanged) {
            this.props.stateChanged(state, selected);
        }
    };

    private handleRemoveAll = () => {
        const { typeSearchWidgetOptions, accokinds, resorts } = this.props;
        const { resortAmenity, resortId, availableAmenityCategories } = this.state;
        let currentResort: MXTS.Amenity | number | undefined | null = resortAmenity || resortId;
        const prefilteredAmenities: number[] = [];
        if (typeSearchWidgetOptions ? (typeSearchWidgetOptions.resortCode ? false : true) : false) {
            currentResort = resortAmenity ? null : resortId ? resortId : undefined;
        }
        if (!currentResort && typeSearchWidgetOptions && typeSearchWidgetOptions.resortCode && !isNaN(+typeSearchWidgetOptions.resortCode)) {
            currentResort = +typeSearchWidgetOptions.resortCode;
        }
        let currentSelectedAccoKinds;
        if (typeSearchWidgetOptions && typeSearchWidgetOptions.accoKindIds && accokinds) {
            currentSelectedAccoKinds = typeSearchWidgetOptions.accoKindIds.map((accoKind) => accoKindById(accokinds, accoKind.value)!);
        }
        let currentSelectedResorts;
        if (typeSearchWidgetOptions && typeSearchWidgetOptions.resorts && resorts) {
            currentSelectedResorts = typeSearchWidgetOptions.resorts.map((resort) => resortById(resorts, resort.value)!);
        }
        if (typeSearchWidgetOptions && typeSearchWidgetOptions.amenityCodes && availableAmenityCategories) {
            const codes = typeSearchWidgetOptions.amenityCodes.split(",");
            availableAmenityCategories.forEach((cat) => {
                if (cat.amenities) {
                    cat.amenities.forEach((amenity) => {
                        if (codes.indexOf(amenity.identifier) > -1) {
                            prefilteredAmenities.push(amenity.amenityId);
                        }
                    });
                }
            });
        }
        const specialCodes = typeSearchWidgetOptions?.specialCodes && typeof typeSearchWidgetOptions.specialCodes === "string" ? typeSearchWidgetOptions.specialCodes.split(",") : [];
        const state = {
            ...this.state,
            resort: currentResort,
            selectedAccoKinds: currentSelectedAccoKinds,
            selectedResorts: currentSelectedResorts,
            startDate: null,
            endDate: null,
            stayPeriodDefId: null,
            selectedSubjects: undefined,
            resortId: currentResort && !isNaN(+currentResort) ? +currentResort : undefined,
            amenityIds: prefilteredAmenities.length > 0 ? prefilteredAmenities : [],
            specialCodes,
            resortAmenity: !currentResort ? null : resortAmenity,
        };
        this.setState(state, () => {
            this.updateNewState(state);
            localStorage.removeItem(LOCAL_STORAGE_KEY);
            this.refreshURL(true);
            if (this.props.stateChanged) {
                this.props.stateChanged(state);
            }
        });
    };

    private updateNewState(newState: Readonly<SearchFacetState>) {
        this.setState({ newState });
    }
}

function scrollToTop() {
    scroller.scrollTo("result", {
        duration: 1500,
        smooth: true,
        containerId: "ContainerElementID",
        offset: -50,
    });
}

export const SearchFacet = wrapProps<SearchFacetBaseProps>(SearchFacetBase);
